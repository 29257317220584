import $ from 'jquery';
import {debounce} from '@exadel/esl/modules/esl-utils/async/debounce';
import {throttle} from '@exadel/esl/modules/esl-utils/async/throttle';

/** @deprecated you might not need JQuery */
export const $window = $(window);

export const WINDOW_ON_RESIZE_DELAY = 200;
const onResizeCallbacks = $.Callbacks();
$window.on('resize', debounce(onResizeCallbacks.fire, WINDOW_ON_RESIZE_DELAY));
/** @deprecated you might not need JQuery */
export const onResize = (callback: (e: JQuery.Event) => void) => { // Run callback on resize
  onResizeCallbacks.add(callback);
  return () => onResizeCallbacks.remove(callback);
};

export const WINDOW_ON_SCROLL_THROTTLE = 50;
const onScrollCallbacks = $.Callbacks();
$window.on('scroll', throttle(onScrollCallbacks.fire, WINDOW_ON_SCROLL_THROTTLE));
/** @deprecated you might not need JQuery */
export const onScroll = (callback: (e: JQuery.Event) => void) => { // Run callback on user scrolling
  onScrollCallbacks.add(callback);
  return () => onScrollCallbacks.remove(callback);
};

/** @deprecated you might not need JQuery */
export const onWindowEvent = (event: string, callback: (event: JQuery.Event) => void) => {
  $window.on(event, callback);
  return () => $window.off(event, callback);
};
