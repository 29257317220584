import {sequentialUID} from '@exadel/esl/modules/esl-utils/misc/uid';

import {debug, error} from 'core/log';
import {get} from 'core/helpers/object';
import {windowHook} from 'core/hooks/view.window.hook';
import {viewportHook} from 'core/hooks/view.viewport.hook';

import type {View, EventCallback, UnsubscriberDefinition, EventActionDefinition} from 'core/view';

const NOT_BUBBLED_EVENTS = [
  // load events not bubbled
  'load', 'loadedmetadata', 'unload', 'error', 'ended',
  'scroll',
  'focus', 'blur',
  'fullscreenchange'
];
const isBubbledEvent = (event: string) => {
  const [domEvent, ns] = (event || '').split('.');
  return ns !== 'self' && NOT_BUBBLED_EVENTS.indexOf(domEvent) === -1;
};


const EVENT_QUERY_PATTERN = /(.*)on:(.+)/;
const PARAM_QUERY_PATTERN = /{(.+?)}/g;

const $document = $(document);
const $body = $(document.body);

/**
 * Check if the parsed term is event query
 * */
export const isEventQuery = (eventQuery: string) => {
  const [selector, event] = (eventQuery || '').split('on:');
  if (!event || !event.trim()) return false;
  return selector.length === 0 || selector.slice(-1) === ' ';
};

/**
 * Collect all event shortcut definitions
 * */
export const getAllEventProperties = (view: View) => {
  const props = new Set();
  do {
    Object.getOwnPropertyNames(view)
      .filter((v) => isEventQuery(v))
      .forEach((v) => props.add(v));
  } while ((view = Object.getPrototypeOf(view)) && view.constructor !== Object);
  return props;
};

const NOT_FOUND_MARKER = '{null}';

export const EVENT_HOOKS = ['{window}', '{viewport}', '{body}', '{document}'];

const compileInjects = (val: string, view: View) => val.replace(PARAM_QUERY_PATTERN, (param: string, paramName: string) => {
  if (EVENT_HOOKS.indexOf(param) !== -1) return param;
  const value = get(view.options, paramName);
  if (typeof value !== 'string') {
    error(`Binding ${param} in query ${val} of ${view.viewName} ignored as incorrect, get: `, value);
    return NOT_FOUND_MARKER;
  }
  if (!value) {
    debug(`Binding ${param} in query ${val} of ${view.viewName} is empty.`);
  }
  return value;
});

const parseEventQuery = (query: string, view: View) => {
  if (!query) return {};

  const eventQuery = query.match(EVENT_QUERY_PATTERN);
  if (!eventQuery || eventQuery.length !== 3) return {};

  const selector = compileInjects((eventQuery[1] || '').trim(), view);
  const event = compileInjects((eventQuery[2] || '').trim(), view);

  for (const hook of EVENT_HOOKS) {
    if (selector && selector.substr(0, hook.length) === hook) {
      return {
        selector: hook,
        subSelector: selector.substr(hook.length).trim(),
        event, query
      };
    }
  }

  return {selector, event, query};
};

/** Add delegated event listener to $el */
const attachEventListener = ($el: JQuery<HTMLElement | Document>, event: string, callback: EventCallback, selector: string, isFnOff = false) => {
  if (!selector) {
    $el.on(event, callback);
    return isFnOff ? () => $el.off(event) : event;
  }
  if (isBubbledEvent(event)) {
    $el.on(event, selector, callback);
    return isFnOff ? () => $el.off(event, selector) : event;
  }
  const $delegate = $el.find(selector);
  $delegate.on(event, callback);
  return () => $delegate.off(event, callback);
};

/** Create event listener */
const createListener = (view: View, selector: string, event: string, subSelector: string, callback: EventCallback) => {
  switch (selector) {
    case '{window}':
      return windowHook(event, callback, view);
    case '{viewport}':
      return viewportHook(event, callback, view);
    case '{body}':
      return attachEventListener($body, `${event}.${view.cid}.${sequentialUID('ve')}`, callback, subSelector, true);
    case '{document}':
      return attachEventListener($document, `${event}.${view.cid}.${sequentialUID('ve')}`, callback, subSelector, true);
    default:
      return attachEventListener(view.$el, `${event}.${view.cid}.${sequentialUID('ve')}`, callback, selector);
  }
};

/** Create event listeners */
const createListeners = (view: View, eventQuery: string, callback: EventCallback): UnsubscriberDefinition[] => {
  const {selector, event, subSelector} = parseEventQuery(eventQuery, view);

  if (!event || event.indexOf(NOT_FOUND_MARKER) !== -1 || selector.indexOf(NOT_FOUND_MARKER) !== -1) return [];

  return event.split(' ').map((e: string) => {
    return createListener(view, selector, e, subSelector, callback);
  });
};

/**
 * Bind eventQuery
 * @param {View} view
 * @param {string} eventQuery
 * @param {function} callback
 * */
export const bindEvent = (view: View, eventQuery: string, callback: EventActionDefinition) => {
  if (!view.$el.length) return;

  if (!callback) return;
  if (typeof callback === 'string') callback = (view as any)[callback];
  if (typeof callback !== 'function') return;
  // TODO: get rid of unclear and redundant marker.
  callback = (callback as any).dontChangeContext ? callback : callback.bind(view);

  createListeners(view, String(eventQuery), callback as EventCallback)
    .forEach((event: () => void) => view.registerEventUnsubscribe(eventQuery, event));
};
