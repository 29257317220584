var MODULE_ALIAS = 'core/focus-states'; 
import $ from 'jquery';
import {isA11yMode, enableA11yMode, disableA11yMode} from 'core/helpers/focus';
import {
  TAB, ENTER, ESC, ALT, SHIFT, CONTROL,
  ARROW_UP, ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT,
  PAGE_UP, PAGE_DOWN, END, HOME, SPACE
} from '@exadel/esl/modules/esl-utils/dom/keys';

const ALLOWED_KEYCODES = [
  TAB, ENTER, ALT, SHIFT, CONTROL, ESC,
  ARROW_UP, ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT,
  PAGE_UP, PAGE_DOWN, END, HOME
];

let firstTimeKeyPressed = true;

/**
 * Behavior to enable or disable the focus styles ("a11y mode")
 */
function focusActiveBehavior() {
  $(window).on('mousedown.focus-event keydown.focus-event touchstart.focus-event', function (e) {
    const isKeyboard = e.type === 'keydown' && e.key;
    // Validation to verify if it was a keyboard event and the key is one of the "allowed" keys
    if (isKeyboard && (ALLOWED_KEYCODES.includes(e.key) || (isA11yMode() && e.key === SPACE))) {
      enableA11yMode();

      const $target = $(e.target);
      // Ignore default action if it is the first TAB key and current focus is outside forms and not in initial state.
      if (firstTimeKeyPressed === true && e.key === TAB &&
        $target.get(0).tagName !== 'BODY' && $target.parents('form').length === 0) {
        e.preventDefault();
      }

      firstTimeKeyPressed = false;
    } else {
      disableA11yMode();
      firstTimeKeyPressed = true;
    }
  });
}

// TODO: get rid after bootstrap modal removal
const BODY_POPUPS = ['.esl-popup'];
function focusModalExclusion() {
  // Exclude from bootstrap focusin handler for modals focus trap
  $(document.body).on('focusin.exclusions', BODY_POPUPS.join(), (e) => e.stopPropagation());
}

let initialized = false;
export default {
  initialize: function initialize() {
    if (initialized) return;
    focusActiveBehavior();
    focusModalExclusion();
    initialized = true;
  }
};

;exports.default.componentName = MODULE_ALIAS;