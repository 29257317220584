import {listen} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';

import {fireAnalyticsEvent} from 'core/helpers/metrics';
import {getAssetId, getDefaultMetrics} from 'core/video-metrics-utils';

import type {ESLMedia} from '@exadel/esl/modules/esl-media/core';

/**
 * Mixin to track HVA for video.
 * No extra options.
 * Observe changes of ESLMedia asset automatically.
 *
 * Usage:
 * ```html
 * <smart-media hva-video-tracker ...></smart-media>
 * ```
 */
export class HPEVideoHvaTracker extends ESLMixinElement {
  public static override is = 'hva-video-tracker';

  /** Video types that supports HVA */
  public static readonly SUPPORTED_TYPES: string[] = ['youtube', 'brightcove', 'bc-audio'];

  // Initial config
  public static readonly MILESTONES: Milestone[] = [
    {percents: 0, label: 'start', completed: false},
    {percents: 25, label: '25', completed: false},
    {percents: 50, label: '50', completed: false},
    {percents: 75, label: '75', completed: false},
    {percents: 100, label: 'complete', completed: false}
  ];

  // Work on ESLMedia only
  public override $host: ESLMedia;

  private timer: number;
  private assetId: string;
  private providerType: string;
  private milestones: Milestone[];

  /** Get current timeline progress */
  public get timeline(): number {
    return this.$host.currentTime / this.$host.duration * 100;
  }

  protected override connectedCallback(): void {
    if (!this.$host.matches('smart-media')) return;

    super.connectedCallback();
    if (this.$host.ready && this.$host.active) this._onStart();
  }

  protected override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.stopTracking(false);
  }

  @listen('esl:media:play')
  protected _onStart(): void {
    this.observe();
  }

  @listen('esl:media:pause esl:media:ended esl:media:detach')
  protected _onSuspension(): void {
    this.stopTracking();
  }

  /** Start observing player */
  public observe(): void {
    if (!HPEVideoHvaTracker.SUPPORTED_TYPES.includes(this.$host.providerType)) return;

    const assetId = getAssetId(this.$host);
    const providerType = this.providerType;
    if (assetId !== this.assetId || providerType !== this.providerType) {
      this.assetId = assetId;
      this.providerType = providerType;
      this.milestones = HPEVideoHvaTracker.MILESTONES.map((ms) => Object.assign({}, ms));
    }

    this.stopTracking(false);
    this.startTracking();
  }

  /** Send track metrics */
  public trackMetrics(name: string): void {
    const metrics = getDefaultMetrics(this.$host);
    fireAnalyticsEvent({
      ...metrics,
      type: `hva.video${name}`
    });
  }

  /** Start tracking player timeline */
  public startTracking(): void {
    if (typeof this.timer !== 'undefined') return;
    this.timer = window.setInterval(this.onMilestoneTrack.bind(this), 1000);

    this.onMilestoneTrack(0);
  }

  /** Stop tracking player timeline */
  public stopTracking(trackEnd = true): void {
    if (typeof this.timer === 'undefined') return;
    window.clearInterval(this.timer);
    delete this.timer;

    // sometimes video stops at ~99%
    if (trackEnd && this.$host.state === 0) {
      this.onMilestoneTrack(100);
    }
  }

  /** Callback to update on timeline progress change */
  protected onMilestoneTrack(timeline: number): void {
    if (!this.$host.active) this.stopTracking();

    timeline = timeline || this.timeline;

    let currentMilestone: Milestone = null;
    const milestones = this.milestones;

    for (let i = 0; i < milestones.length; i++) {
      if (timeline >= milestones[i].percents && !milestones[i].completed) {
        milestones[i].completed = true;
        currentMilestone = milestones[i];
      }
    }

    if (!currentMilestone) return;
    this.trackMetrics(currentMilestone.label);

    if (currentMilestone.percents >= milestones[milestones.length - 1].percents) {
      this.stopTracking();
    }
  }
}

interface Milestone {
  percents: number;
  label: string;
  completed: boolean;
}

export default {
  initialize: () => HPEVideoHvaTracker.register()
};
