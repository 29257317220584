__webpack_public_path__ = window['~hpe~'] && window['~hpe~'].root || '/js/'; // eslint-disable-line

// Mark HTML with JS support marker
document.documentElement.classList.add('js');
document.documentElement.classList.remove('no-js');

// JQuery global init, needed for old plugins
// eslint-disable-next-line @typescript-eslint/no-require-imports
window.$ = window.jQuery = require('jquery');

// eslint-disable-next-line @typescript-eslint/no-require-imports
require('core/core');

// bootstrap 3.*.* imports (should be migrated and removed in the future)
// eslint-disable-next-line @typescript-eslint/no-require-imports
require('core/vendor/bootstrap.transition'); // Used in carousels but can be updated or replaced
// eslint-disable-next-line @typescript-eslint/no-require-imports
require('core/vendor/bootstrap.modal'); // TODO: move on components level or replace (Topic Story, Narrative, Capabilities)
