var MODULE_ALIAS = 'bundles/compilers/generic.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('generic.all', ($) => {
  /** BUNDLE COMPONENTS **/
  initComponent.$if($('.bg-wrapper-component')) && initComponent(require('design-3.0/background-wrapper'), $('.bg-wrapper-component'));
  initComponent.$if($('.carousel-marquee')) && initComponent(require('design-3.0/marquee'), $('.carousel-marquee'));
  initComponent.$if($('.js-smart-marquee')) && initComponent(require('design-3.0/smart-marquee'), $('.js-smart-marquee'));

 initComponent.$if($('.marquee-nav-carousel')) && require.ensure('design-3.0/marquee-nav-carousel/marquee-nav-carousel',
            function(require) { initComponent.$if($('.marquee-nav-carousel')) && initComponent(require('design-3.0/marquee-nav-carousel/marquee-nav-carousel'), $('.marquee-nav-carousel'));;},
            function(error) { require('core/log').error("Can't load design-3.0/marquee-nav-carousel/marquee-nav-carousel", error); },
            'design-3.0/marquee-nav-carousel/marquee-nav-carousel'
            );

 initComponent.$if($('.valign-txt-only')) && require.ensure('hpe.columns.container',
            function(require) { initComponent.$if($('.valign-txt-only')) && initComponent(require('hpe.columns.container'), $('.valign-txt-only'));;},
            function(error) { require('core/log').error("Can't load hpe.columns.container", error); },
            'hpe.columns.container'
            );

 initComponent.$if($('.hpe-content-block')) && require.ensure('design-3.0/content-block',
            function(require) { initComponent.$if($('.hpe-content-block')) && initComponent(require('design-3.0/content-block'), $('.hpe-content-block'));;},
            function(error) { require('core/log').error("Can't load design-3.0/content-block", error); },
            'design-3.0/content-block'
            );
 initComponent.$if($('.introduction')) && require.ensure('design-3.0/introduction',
            function(require) { initComponent.$if($('.introduction')) && initComponent(require('design-3.0/introduction'), $('.introduction'));;},
            function(error) { require('core/log').error("Can't load design-3.0/introduction", error); },
            'design-3.0/introduction'
            );
 initComponent.$if($('.topic-story')) && require.ensure('design-3.0/topic-story',
            function(require) { initComponent.$if($('.topic-story')) && initComponent(require('design-3.0/topic-story'), $('.topic-story'));;},
            function(error) { require('core/log').error("Can't load design-3.0/topic-story", error); },
            'design-3.0/topic-story'
            );
 initComponent.$if($('.hpe-accolade-carousel-v3')) && require.ensure('design-3.0/accolade-carousel-v3',
            function(require) { initComponent.$if($('.hpe-accolade-carousel-v3')) && initComponent(require('design-3.0/accolade-carousel-v3'), $('.hpe-accolade-carousel-v3'));;},
            function(error) { require('core/log').error("Can't load design-3.0/accolade-carousel-v3", error); },
            'design-3.0/accolade-carousel-v3'
            );
 initComponent.$if($('.whats-new')) && require.ensure('design-3.0/whats-new',
            function(require) { initComponent.$if($('.whats-new')) && initComponent(require('design-3.0/whats-new'), $('.whats-new'));;},
            function(error) { require('core/log').error("Can't load design-3.0/whats-new", error); },
            'design-3.0/whats-new'
            );
 initComponent.$if($('.tab-carousel')) && require.ensure('design-3.0/tab-carousel',
            function(require) { initComponent.$if($('.tab-carousel')) && initComponent(require('design-3.0/tab-carousel'), $('.tab-carousel'));;},
            function(error) { require('core/log').error("Can't load design-3.0/tab-carousel", error); },
            'design-3.0/tab-carousel'
            );
 initComponent.$if($('.case-study-v3')) && require.ensure('design-3.0/case-study-3.0',
            function(require) { initComponent.$if($('.case-study-v3')) && initComponent(require('design-3.0/case-study-3.0'), $('.case-study-v3'));;},
            function(error) { require('core/log').error("Can't load design-3.0/case-study-3.0", error); },
            'design-3.0/case-study-3.0'
            );
 initComponent.$if($('.related-resource'), {minCardHeight: 250}) && require.ensure('design-3.0/around-hpe',
            function(require) { initComponent.$if($('.related-resource'), {minCardHeight: 250}) && initComponent(require('design-3.0/around-hpe'), $('.related-resource'), {minCardHeight: 250});;},
            function(error) { require('core/log').error("Can't load design-3.0/around-hpe", error); },
            'design-3.0/around-hpe'
            );
 initComponent.$if($('.technical-videos')) && require.ensure('design-3.0/tech-videos',
            function(require) { initComponent.$if($('.technical-videos')) && initComponent(require('design-3.0/tech-videos'), $('.technical-videos'));;},
            function(error) { require('core/log').error("Can't load design-3.0/tech-videos", error); },
            'design-3.0/tech-videos'
            );
 initComponent.$if($('.capabilities-component-wrapper')) && require.ensure('design-3.0/capabilities-wrapper',
            function(require) { initComponent.$if($('.capabilities-component-wrapper')) && initComponent(require('design-3.0/capabilities-wrapper'), $('.capabilities-component-wrapper'));;},
            function(error) { require('core/log').error("Can't load design-3.0/capabilities-wrapper", error); },
            'design-3.0/capabilities-wrapper'
            );
 initComponent.$if($('.simple-routing:not(.edit-mode)')) && require.ensure('design-3.0/simple-routing',
            function(require) { initComponent.$if($('.simple-routing:not(.edit-mode)')) && initComponent(require('design-3.0/simple-routing'), $('.simple-routing:not(.edit-mode)'));;},
            function(error) { require('core/log').error("Can't load design-3.0/simple-routing", error); },
            'design-3.0/simple-routing'
            );
 initComponent.$if($('.elastic-list')) && require.ensure('design-3.0/elastic-list',
            function(require) { initComponent.$if($('.elastic-list')) && initComponent(require('design-3.0/elastic-list'), $('.elastic-list'));;},
            function(error) { require('core/log').error("Can't load design-3.0/elastic-list", error); },
            'design-3.0/elastic-list'
            );
 initComponent.$if($('.accordion-container-v3')) && require.ensure('design-3.0/accordion-v3',
            function(require) { initComponent.$if($('.accordion-container-v3')) && initComponent(require('design-3.0/accordion-v3'), $('.accordion-container-v3'));;},
            function(error) { require('core/log').error("Can't load design-3.0/accordion-v3", error); },
            'design-3.0/accordion-v3'
            );
 initComponent.$if($('.category-portfolio')) && require.ensure('design-3.0/category-portfolio',
            function(require) { initComponent.$if($('.category-portfolio')) && initComponent(require('design-3.0/category-portfolio'), $('.category-portfolio'));;},
            function(error) { require('core/log').error("Can't load design-3.0/category-portfolio", error); },
            'design-3.0/category-portfolio'
            );
 initComponent.$if($('.browse-by-router')) && require.ensure('design-3.0/browse-by-router',
            function(require) { initComponent.$if($('.browse-by-router')) && initComponent(require('design-3.0/browse-by-router'), $('.browse-by-router'));;},
            function(error) { require('core/log').error("Can't load design-3.0/browse-by-router", error); },
            'design-3.0/browse-by-router'
            );
 initComponent.$if($('.workload')) && require.ensure('design-3.0/workload-selector',
            function(require) { initComponent.$if($('.workload')) && initComponent(require('design-3.0/workload-selector'), $('.workload'));;},
            function(error) { require('core/log').error("Can't load design-3.0/workload-selector", error); },
            'design-3.0/workload-selector'
            );
 initComponent.$if($('.isv-partners')) && require.ensure('design-3.0/partners',
            function(require) { initComponent.$if($('.isv-partners')) && initComponent(require('design-3.0/partners'), $('.isv-partners'));;},
            function(error) { require('core/log').error("Can't load design-3.0/partners", error); },
            'design-3.0/partners'
            );
 initComponent.$if($('.tech-specs')) && require.ensure('design-3.0/tech-specs',
            function(require) { initComponent.$if($('.tech-specs')) && initComponent(require('design-3.0/tech-specs'), $('.tech-specs'));;},
            function(error) { require('core/log').error("Can't load design-3.0/tech-specs", error); },
            'design-3.0/tech-specs'
            );
 initComponent.$if($('.table-v2')) && require.ensure('design-3.0/table-v2',
            function(require) { initComponent.$if($('.table-v2')) && initComponent(require('design-3.0/table-v2'), $('.table-v2'));;},
            function(error) { require('core/log').error("Can't load design-3.0/table-v2", error); },
            'design-3.0/table-v2'
            );

 initComponent.$if($('.hpe-resource-3')) && require.ensure('design-3.0/resource-3.0',
            function(require) { initComponent.$if($('.hpe-resource-3')) && initComponent(require('design-3.0/resource-3.0'), $('.hpe-resource-3'));;},
            function(error) { require('core/log').error("Can't load design-3.0/resource-3.0", error); },
            'design-3.0/resource-3.0'
            );
 initComponent.$if($('.image-gallery')) && require.ensure('design-3.0/image-gallery',
            function(require) { initComponent.$if($('.image-gallery')) && initComponent(require('design-3.0/image-gallery'), $('.image-gallery'));;},
            function(error) { require('core/log').error("Can't load design-3.0/image-gallery", error); },
            'design-3.0/image-gallery'
            );
 initComponent.$if($('.agenda')) && require.ensure('design-3.0/agenda',
            function(require) { initComponent.$if($('.agenda')) && initComponent(require('design-3.0/agenda'), $('.agenda'));;},
            function(error) { require('core/log').error("Can't load design-3.0/agenda", error); },
            'design-3.0/agenda'
            );
 initComponent.$if($('.ticker')) && require.ensure('design-3.0/ticker',
            function(require) { initComponent.$if($('.ticker')) && initComponent(require('design-3.0/ticker'), $('.ticker'));;},
            function(error) { require('core/log').error("Can't load design-3.0/ticker", error); },
            'design-3.0/ticker'
            );
 initComponent.$if($('.smb-pg')) && require.ensure('design-3.0/smb-promo-group',
            function(require) { initComponent.$if($('.smb-pg')) && initComponent(require('design-3.0/smb-promo-group'), $('.smb-pg'));;},
            function(error) { require('core/log').error("Can't load design-3.0/smb-promo-group", error); },
            'design-3.0/smb-promo-group'
            );
 initComponent.$if($('.js-account-editor-wrapper')) && require.ensure('design-3.0/account-editor-wrapper',
            function(require) { initComponent.$if($('.js-account-editor-wrapper')) && initComponent(require('design-3.0/account-editor-wrapper'), $('.js-account-editor-wrapper'));;},
            function(error) { require('core/log').error("Can't load design-3.0/account-editor-wrapper", error); },
            'design-3.0/account-editor-wrapper'
            );
 initComponent.$if($('.js-account-editor-subscriptions-section')) && require.ensure('design-3.0/account-modules/subscriptions/section',
            function(require) { initComponent.$if($('.js-account-editor-subscriptions-section')) && initComponent(require('design-3.0/account-modules/subscriptions/section'), $('.js-account-editor-subscriptions-section'));;},
            function(error) { require('core/log').error("Can't load design-3.0/account-modules/subscriptions/section", error); },
            'design-3.0/account-modules/subscriptions/section'
            );
 initComponent.$if($('.contact-banner')) && require.ensure('design-3.0/contact-banner',
            function(require) { initComponent.$if($('.contact-banner')) && initComponent(require('design-3.0/contact-banner'), $('.contact-banner'));;},
            function(error) { require('core/log').error("Can't load design-3.0/contact-banner", error); },
            'design-3.0/contact-banner'
            );
 initComponent.$if($('.chapter-tabs-v3')) && require.ensure('design-3.0/chapter-tabs-v3',
            function(require) { initComponent.$if($('.chapter-tabs-v3')) && initComponent(require('design-3.0/chapter-tabs-v3'), $('.chapter-tabs-v3'));;},
            function(error) { require('core/log').error("Can't load design-3.0/chapter-tabs-v3", error); },
            'design-3.0/chapter-tabs-v3'
            );
 initComponent.$if($('.one-up-card')) && require.ensure('design-3.0/one-up-card',
            function(require) { initComponent.$if($('.one-up-card')) && initComponent(require('design-3.0/one-up-card'), $('.one-up-card'));;},
            function(error) { require('core/log').error("Can't load design-3.0/one-up-card", error); },
            'design-3.0/one-up-card'
            );
 initComponent.$if($('.flow-diagram')) && require.ensure('design-3.0/flow-diagram',
            function(require) { initComponent.$if($('.flow-diagram')) && initComponent(require('design-3.0/flow-diagram'), $('.flow-diagram'));;},
            function(error) { require('core/log').error("Can't load design-3.0/flow-diagram", error); },
            'design-3.0/flow-diagram'
            );
 initComponent.$if($('.compare-diagram')) && require.ensure('design-3.0/compare-diagram',
            function(require) { initComponent.$if($('.compare-diagram')) && initComponent(require('design-3.0/compare-diagram'), $('.compare-diagram'));;},
            function(error) { require('core/log').error("Can't load design-3.0/compare-diagram", error); },
            'design-3.0/compare-diagram'
            );
 initComponent.$if($('.offering-router')) && require.ensure('design-3.0/offering-router',
            function(require) { initComponent.$if($('.offering-router')) && initComponent(require('design-3.0/offering-router'), $('.offering-router'));;},
            function(error) { require('core/log').error("Can't load design-3.0/offering-router", error); },
            'design-3.0/offering-router'
            );

 initComponent.$if($('.product-promo-carousel')) && require.ensure('design-3.0/product-promo.carousel',
            function(require) { initComponent.$if($('.product-promo-carousel')) && initComponent(require('design-3.0/product-promo.carousel'), $('.product-promo-carousel'));;},
            function(error) { require('core/log').error("Can't load design-3.0/product-promo.carousel", error); },
            'design-3.0/product-promo.carousel'
            );
 initComponent.$if($('.around-hpe')) && require.ensure('design-3.0/around-hpe',
            function(require) { initComponent.$if($('.around-hpe')) && initComponent(require('design-3.0/around-hpe'), $('.around-hpe'));;},
            function(error) { require('core/log').error("Can't load design-3.0/around-hpe", error); },
            'design-3.0/around-hpe'
            );
 initComponent.$if($('.psnow-recommendations-wrapper')) && require.ensure('design-3.0/psnow-recommendations',
            function(require) { initComponent.$if($('.psnow-recommendations-wrapper')) && initComponent(require('design-3.0/psnow-recommendations'), $('.psnow-recommendations-wrapper'));;},
            function(error) { require('core/log').error("Can't load design-3.0/psnow-recommendations", error); },
            'design-3.0/psnow-recommendations'
            );
 initComponent.$if($('iframe-renderer')) && require.ensure('design-3.0/iframe-renderer',
            function(require) { initComponent.$if($('iframe-renderer')) && initComponent(require('design-3.0/iframe-renderer'), $('iframe-renderer'));;},
            function(error) { require('core/log').error("Can't load design-3.0/iframe-renderer", error); },
            'design-3.0/iframe-renderer'
            );
 initComponent.$if($('.customer-story-carousel')) && require.ensure('design-3.0/customer-story-carousel',
            function(require) { initComponent.$if($('.customer-story-carousel')) && initComponent(require('design-3.0/customer-story-carousel'), $('.customer-story-carousel'));;},
            function(error) { require('core/log').error("Can't load design-3.0/customer-story-carousel", error); },
            'design-3.0/customer-story-carousel'
            );
 initComponent.$if($('.recommendations-section')) && require.ensure('design-3.0/recommendations-section',
            function(require) { initComponent.$if($('.recommendations-section')) && initComponent(require('design-3.0/recommendations-section'), $('.recommendations-section'));;},
            function(error) { require('core/log').error("Can't load design-3.0/recommendations-section", error); },
            'design-3.0/recommendations-section'
            );

  // Fragments specific
  initComponent(require('design-3.0/narrative-init-strategy'), null, $);
  initComponent(require('design-3.0/portfolio-init-strategy'), null, $);

  // Common Pages Async Components
 initComponent.$if($('.collateral-page')) && require.ensure('collateral/collateral-page',
            function(require) { initComponent.$if($('.collateral-page')) && initComponent(require('collateral/collateral-page'), $('.collateral-page'));;},
            function(error) { require('core/log').error("Can't load collateral/collateral-page", error); },
            'collateral/collateral-page'
            );
 initComponent.$if($('.gsr-component')) && require.ensure('design-3.0/search-results',
            function(require) { initComponent.$if($('.gsr-component')) && initComponent(require('design-3.0/search-results'), $('.gsr-component'));;},
            function(error) { require('core/log').error("Can't load design-3.0/search-results", error); },
            'design-3.0/search-results'
            );
 initComponent.$if($('.js-page-redirect')) && require.ensure('design-3.0/page-redirect',
            function(require) { initComponent.$if($('.js-page-redirect')) && initComponent(require('design-3.0/page-redirect'), $('.js-page-redirect'));;},
            function(error) { require('core/log').error("Can't load design-3.0/page-redirect", error); },
            'design-3.0/page-redirect'
            );
});
