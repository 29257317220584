import Cookies from 'js-cookie';
import {isAEMAuthorMode} from 'core/helpers/aem';
import {getCurrentDomain, IS_LOCAL} from 'core/helpers/location';
import {getConfig, pageLocaleValues, Locale} from 'core/helpers/config';

export type PRIVACY_PREFERENCES = 'TECHNICAL' | 'ANALYTICS' | 'PERSONALIZATION' | 'THIRDPARTY';
export const PRIVACY_COOKIE_PREFERENCES = 'hpeuck_prefs';

const COOKIES_CONFIG: Cookies.CookieAttributes = {expires: 365};
if (!IS_LOCAL) COOKIES_CONFIG.domain = getCurrentDomain();

Cookies.remove('hpe_locale', COOKIES_CONFIG); // Cleanup previous namespace

const parseLang = (lang: string) => lang ? (lang.split('-')[0]) : null;

/**
 * return indication if user cookie preferences are set
 * @returns {boolean} if the user has set preferences or not
 */
export function hasPrivacyPreferences(): boolean {
  const cookie = Cookies.get(PRIVACY_COOKIE_PREFERENCES);
  return typeof cookie !== 'undefined';
}

/**
 * store the passed user preferences in a cookie
 * @returns {object} preferences object with key/value object of privacy cookie types, where true
 * values mean the user has "opted in" to store that category of cookie, and false values mean the user
 * has "opted out" of accepting that category of cookie
 */
export function getPrivacyPreferences(): Partial<Record<PRIVACY_PREFERENCES, boolean>> {
  const cookie = Cookies.get(PRIVACY_COOKIE_PREFERENCES);

  // the order of the cookie values is always: technical, analytics, personalization, third party
  // if a value is true, the user has opted in to that category of cookies
  // if the value is false, the user has opted out of that category of cookies
  if (cookie) {
    const parts = cookie.toString().split('').map((el) => el === '1');
    const [TECHNICAL, ANALYTICS, PERSONALIZATION, THIRDPARTY] = parts;
    return {TECHNICAL, ANALYTICS, PERSONALIZATION, THIRDPARTY};
  }
  return {};
}

/** Updates user locale preference cookies */
export function setUserPreference({language, country}: Locale, force = false): void {
  if (!force && hasPrivacyPreferences() && !getPrivacyPreferences().TECHNICAL) return;
  if (language && language !== parseLang(Cookies.get('lang'))) {
    Cookies.set('lang', language, COOKIES_CONFIG);
  }
  if (country) {
    Cookies.set('cc', country, COOKIES_CONFIG);
  }
}

/** Reads user locale preference from cookies */
export function getUserPreference(): Locale | null {
  const country = Cookies.get('cc');
  const language = parseLang(Cookies.get('lang'));
  return country && language ? {country, language} : null;
}

const LANG_CODE_REGEX = /^[a-z]{2}$/i;

/** Redirects to the same page but on the passed locale. Ignores if Locale is incorrect */
export function redirectByUserPreference(preference: Locale = getUserPreference()): void {
  if (!preference || !LANG_CODE_REGEX.test(preference.language)) return;
  const {country, language} = pageLocaleValues();
  const currentURLCode = `/${country}/${language}/`;
  const redirectURLCode = `/${preference.country}/${preference.language}/`.toLowerCase();
  window.location.assign(location.href.replace(currentURLCode, redirectURLCode));
}

/**
 * Redirect logic according to HPED-30589
 * 1. If the user does not have a cookie, create one with the page locale
 * 2. If the user has a cookie, but the page locale is different, depending on the localeCookieStrategy:
 *   a. rewrite - rewrite the cookie with the page locale
 *   b. redirect - redirect to the same page but on the cookie locale
 *   c. default - do nothing (keep the cookie as is)
 * The logic is skipped in AEM Author mode
 */
export function handleLangCookies(): void {
  if (isAEMAuthorMode()) return;

  const pageLocale = pageLocaleValues();
  const userPreference = getUserPreference();

  if (!userPreference) return setUserPreference(pageLocale, true);
  if (pageLocale.country === userPreference.country && pageLocale.language === userPreference.language) return;

  switch (getConfig('localeCookieStrategy')) {
    case 'rewrite':
      setUserPreference(pageLocale);
      break;
    case 'redirect':
      redirectByUserPreference(userPreference);
      break;
  }
}
