import {ESLMediaRuleList} from '@exadel/esl/modules/esl-media-query/core';
import {debug, error} from 'core/log';
import {$window} from 'core/helpers/window';

import BREAKPOINTS from '../config/breakpoints.json';

export {BREAKPOINTS};

export type BreakpointName = keyof typeof BREAKPOINTS;
export type BreakpointConfig = typeof BREAKPOINTS[BreakpointName];

export const BP_CHANGE_EVENT = 'hpe.breakpointchange';

/** Reusable constant with full breakpoints list query */
export const DEFAULT_BP_TUPLE = '@xs|@sm|@md|@lg|@xl';
/** Reusable constant with full list of main breakpoints */
export const DEFAULT_BP_NAMES_TUPLE = 'xs|sm|md|lg|xl';

/** Reusable {@link ESLMediaRuleList} constant that observes main breakpoints state change */
export const DEFAULT_BP_QUERY = ESLMediaRuleList.parseTuple(DEFAULT_BP_TUPLE, DEFAULT_BP_NAMES_TUPLE);

/** Parses adaptive value to optimized {@link ESLMediaRuleList} */
export const parseAdaptiveValue = (value: string): ESLMediaRuleList => {
  if (value.includes('=>')) return ESLMediaRuleList.parseQuery(value);
  const length = value.split('|').length;
  const mask = ['all', '@+sm', '@+md', '@+lg', '@+xl'].slice(0, length).join('|');
  return ESLMediaRuleList.parseTuple(mask, value);
};

let current: BreakpointName = null;
let previous: BreakpointName = null;

const onScreenBreakpointChange = (size: BreakpointName) => {
  previous = current || size;
  current = size;

  $window.trigger(BP_CHANGE_EVENT, [current, previous]);

  const status = current === previous ? `updated to ${current}` : `changed from ${previous} to ${current}`;
  debug(`System: Screen resolution was ${status}.`);
};

/** @deprecated use {@link ESLMediaQuery} */
export const onCustomBreakpoint = (min: number, max: number, callback: () => void) => {
  const mediaQueryList = window.matchMedia(`screen and (min-width: ${min}px) and (max-width: ${max}px)`);
  mediaQueryList.addListener((mediaQueryListEvent) => mediaQueryListEvent.matches && callback());
  mediaQueryList.matches && callback();
};

// Init HPE breakpoints
Object.keys(BREAKPOINTS).forEach((size: BreakpointName) => {
  const bp = BREAKPOINTS[size];
  onCustomBreakpoint(bp.min, bp.max, onScreenBreakpointChange.bind(null, size));
});

/**
 * @deprecated
 * @return {string} - current screen breakpoint name
 * */
export const getCurrentBreakpoint = () => current;

/**
 * @deprecated
 * @return {string} - previous screen breakpoint name
 * Equals to current breakpoint if no screen size changes were made yet
 * */
export const getPreviousBreakpoint = () => previous;

/**
 * @param name
 * @return {boolean} does name contains current screen breakpoint name
 * @deprecated use {@link ESLMediaQuery}
 * */
export const isBreakpoint = (name: BreakpointName | BreakpointName[]): boolean => name.indexOf(current) !== -1;

/**
 * @param {string} name - screen breakpoint name
 * @return {{min: number, max: number}} - breakpoint config
 * */
export const getConfigFor = (name: BreakpointName): BreakpointConfig => {
  if (!BREAKPOINTS[name]) error(`Invalid breakpoint name: ${name}.`);
  return BREAKPOINTS[name];
};

/** @deprecated use {@link ESLMediaQuery} */
export function parseViewportItems(
  viewportString: string,
  keyMapper?: (breakpoint: string, value: string) => string
): Record<string, number>;
/** @deprecated use {@link ESLMediaQuery} */
export function parseViewportItems<V>(
  viewportString: string,
  keyMapper: (breakpoint: string, value: string) => string,
  valueMapper: (breakpoint: string, value: string) => V
): Record<string, V>;
export function parseViewportItems(
  viewportString: string,
  keyMapper: (breakpoint: string, value: string) => string = (breakpoint) => breakpoint,
  valueMapper: (breakpoint: string, value: string) => any = (breakpoint, value) => +value
): Record<string, any> {
  const breakpoints = Object.keys(BREAKPOINTS);
  const viewportObject: Record<string, any> = {};
  viewportString.split('|').forEach((value) => {
    const breakpoint = breakpoints.shift();
    const key = keyMapper(breakpoint, value);
    if (typeof key === 'string' || typeof key === 'number') {
      viewportObject[key] = valueMapper(breakpoint, value);
    }
  });
  return viewportObject;
}

/**
 * Fire global breakpoints events on start
 * */
export const initialize = () => {
  setTimeout(() => $window.trigger(BP_CHANGE_EVENT, current)); // Fallback for old code (es5)
};

export default {initialize};
