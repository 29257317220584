import {unwrap} from 'core/helpers/dom';
import {isAEMAuthorMode} from 'core/helpers/aem';
import {setHeaderTheme} from 'core/helpers/themes';

export default {
  name: 'bg-wrapper',
  async initialize(el: JQuery<HTMLElement>) {
    if (isAEMAuthorMode()) return;
    const bgWrapper = unwrap(el);

    if (bgWrapper.classList.contains('bwc-wrapper-underlap')) {
      setHeaderTheme(bgWrapper.classList.contains('dark-theme') ? 'dark' : 'light');
    }

    if (bgWrapper.querySelector('[bwc-wrapper-parallax]')) {
      const {BGWParallaxMixin} = await import(
        /* webpackChunkName: "design-3.0/background-wrapper-parallax" */
        'design-3.0/background-wrapper-parallax'
      );
      BGWParallaxMixin.register();
    }
  }
};
