import './common/pre-init';
import './common/hpe-wc-module';

import 'core/dependencies'; // core root bundle dependencies list
import 'core/helpers/bf-cache-fix';
import 'core/helpers/browser';
import 'core/legacy/touchevents';

import {handleLangCookies} from 'core/helpers/common-cookies';

import {onDocumentReady} from '@exadel/esl/modules/esl-utils/dom/ready';

import {debug} from 'core/log';
import {$compile} from 'core/core';
import {ChatApi} from 'hpe-chats/core/api';

// Compilers
import './compilers/core.compiler.es6';
import './compilers/gn.compiler.es6';
import './compilers/generic.compiler.es6';
import './compilers/newsroom.compiler.es6';
import './compilers/browsing-page.compiler.es6';
import './compilers/my-account.compiler.es6';
import './compilers/discover.compiler.es6';
import './compilers/forms.compiler.es6';
import './compilers/unicorn.compiler.es6';
import './compilers/third-party-widgets.compiler.es6';

// Get all components in the body and compile it
onDocumentReady(() => {
  handleLangCookies();

  debug('System: Start components initialization.');

  // Compilers
  $compile(document.body);

  // CHATS
  ChatApi.load()
    .catch(() => debug('Problem with Chat API loading'));

  debug('System: All global components were initialized.');
});

debug('System: Script "hpe-generic-v3.js" was loaded.');
