import Cookies from 'js-cookie';

export interface UtmParamConfigItem {
  name: string;
  required: boolean;
}

export const JUMP_ID_PARAM = 'jumpid';
export const DEFAULT_UTM_PARAMS_CONFIG: UtmParamConfigItem[] = [
  {name: 'utm_source', required: true},
  {name: 'utm_medium', required: true},
  {name: 'utm_campaign', required: true},
  {name: 'utm_content', required: true},
  {name: 'utm_term', required: false},
  {name: 'utm_geo', required: false}
];

export const validateUtmSet = (utmParams: Record<string, any>, utmParamsConfig: UtmParamConfigItem[]) => {
  return utmParamsConfig.every((param) => (utmParams[param.name] || !param.required));
};

export const getUtmUrlParams = (utmParamsConfig: UtmParamConfigItem[]): Record<string, any> => {
  const {searchParams: usp} = new URL(window.location.href);
  const utmParams: Record<string, any> = {};

  utmParamsConfig.forEach((param) => {
    const value = usp.get(param.name);
    utmParams[param.name] = value || '';
  });

  return utmParams;
};

export const getUtmCookieParams = (utmParamsConfig: UtmParamConfigItem[]): Record<string, any> => {
  const utmParams: Record<string, any> = {};

  utmParamsConfig.forEach((param) => {
    const value = Cookies.get(param.name);
    utmParams[param.name] = value || '';
  });

  return utmParams;
};

/**
 * Looks for required based on config params in url.
 * If any of required params not found in url, looks available params in cookies.
 * If nothing found in cookies, returns empty object.
 * @param utmParamsConfig - config for utm params {@link UtmParamConfigItem}
 */
export const getUtmParams = (utmParamsConfig: UtmParamConfigItem[]): Record<string, any> => {
  utmParamsConfig = utmParamsConfig.length > 0 ? utmParamsConfig : DEFAULT_UTM_PARAMS_CONFIG;
  const urlParams = getUtmUrlParams(utmParamsConfig);

  if (validateUtmSet(urlParams, utmParamsConfig)) {
    return urlParams;
  }

  return getUtmCookieParams(utmParamsConfig);
};

export const getJumpId = () => {
  const {searchParams: usp} = new URL(window.location.href);

  return usp.get(JUMP_ID_PARAM);
};
