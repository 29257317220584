var MODULE_ALIAS = 'bundles/compilers/discover.compiler'; 
import {createCompiler} from 'core/core';
import {initComponent} from 'core/helpers/component';

export const compile = createCompiler('discover', ($) => {
 initComponent.$if($('.js-video-gallery-v3')) && require.ensure('discover/video-gallery-v3',
            function(require) { initComponent.$if($('.js-video-gallery-v3')) && initComponent(require('discover/video-gallery-v3'), $('.js-video-gallery-v3'));;},
            function(error) { require('core/log').error("Can't load discover/video-gallery-v3", error); },
            'discover/video-gallery-v3'
            );
});
