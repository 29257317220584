/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const TAB = 9;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const ENTER = 13;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const ALT = 18;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const PAUSE = 19;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const SPACE = 32;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const END = 35;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const HOME = 36;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const ARROW_LEFT = 37;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const ARROW_RIGHT = 39;
/** @deprecated use event.key instead, literals can be found in {@link `@exadel/esl/modules/esl-utils/dom/keys`}*/
export const ARROW_DOWN = 40;
