const $body = $('body');

interface SpinnerParams {
  el?: JQuery | HTMLElement;
  cls?: string;
}

function show(options: SpinnerParams = {}): void {
  // eslint-disable-next-line prefer-const
  let {el, cls} = Object.assign({el: $body, cls: 'hpe-spinner'}, options);
  el = $(el);
  (el !== $body && el.css('position') === 'static') && el.css('position', 'relative');
  let spinner = el.children('.hpe-spinner, .hpe-grommet-spinner');
  (!spinner.length) && (spinner = $('<div/>', {'class': `${cls} fade`}).appendTo(el));
  let i = spinner.data('spinner.show.i') || 0;
  if (!i && spinner.hasClass('in')) i = 1; // Fallback for inline spinner
  spinner.data('spinner.show.i', ++i).addClass('in');
}

function hide(el: JQuery | HTMLElement = $body): void {
  el = $(el);
  const spinner = el.children('.hpe-spinner, .hpe-grommet-spinner');
  let i = spinner.data('spinner.show.i') || 0;
  if (!spinner.hasClass('in')) return void 0;
  i = Math.max(--i, 0);
  spinner.data('spinner.show.i', i);
  (!i) && spinner.removeClass('in');
}

export const SpinnerService = {show, hide};

export default SpinnerService;
