import $ from 'jquery';
import {debug} from 'core/log';
import {unwrap} from 'core/helpers/dom';
import {onceViewportEnter} from 'core/helpers/viewport';

import type {View, ViewOptions} from 'core/view';

export type ViewConstructable = new (opt: ViewOptions) => View;
export type HPEPureComponent = {
  name?: string;
  supportAsyncInit?: boolean;
  initialize: ($el: JQuery<HTMLElement>) => void;
};
export type HPEComponent = ViewConstructable | HPEPureComponent;

interface InitComponentBasicSignature {
  (classPath: string): void;
  (component: HPEPureComponent): void;
  (classPath: string, element: string | HTMLElement | JQuery, options?: ViewOptions): void;
  (component: HPEComponent, element: string | HTMLElement | JQuery, options?: ViewOptions): void;
}
interface InitComponentSignature extends InitComponentBasicSignature {
  async: InitComponentBasicSignature;
  $if: (element: string | JQuery<HTMLElement>) => boolean;
}

export const initComponent: InitComponentSignature = (
  Class: any,
  element?: any,
  options: ViewOptions = {}
): void => {
  const init = () => {
    if (element) {
      initView(Class, element, options);
    } else {
      Class.initialize(options);
    }
  };
  Class.__esModule && Class.default && (Class = Class.default);
  Class.supportAsyncInit ? setTimeout(init, 50) : init();
};

function initView(
  Class: any,
  element: string | HTMLElement | JQuery<HTMLElement>,
  options: Record<string, any> = {}
): void {
  const initialized: HTMLElement[] = [];

  if (typeof element === 'string') {
    options.initSelector = element;
  } else {
    options.initSelector = unwrap(element)?.classList[0] || '';
  }

  $(element as any).each((i: number, domElement: HTMLElement) => {
    const initElement = () => {
      const $element = $(domElement);

      if (alreadyInitialized(Class, $element)) {
        debug('Component Initialization: ', getClassName(Class), ' already initialized on ', domElement);
        return;
      }

      if (Class.initialize) {
        Class.initialize($element, options);
      } else {
        options.el = domElement;
        new Class(options);
      }

      markAsInitialized(Class, $element);
      initialized.push(domElement);
    };

    if (Class.supportLazyInit) {
      onceViewportEnter(domElement, () => initElement());
    } else {
      initElement();
    }
  });
  initialized.length && debug(
    `${Class.supportAsyncInit ? 'Async' : ''} Component Initialization: `,
    getClassName(Class),
    ' was initialized on ',
    ...initialized
  );
}

/** Check if the element exists on the page */
initComponent.$if = (element: string | JQuery<HTMLElement>) => {
  if (typeof element === 'string') {
    return !!document.querySelector(element);
  } else if (typeof element.length !== 'undefined') {
    return !!element.length;
  }
  return !!element;
};

/** Post processed method to load component async and lazy (only if presented on the page) */
initComponent.async = () => {
  // see ui.frontend/local-dev/tools/hpe-hooks-loader.js
};

/** Get debug information about class */
function getClassName(Class: typeof View): string {
  if (Object.hasOwnProperty.call(Class, 'componentName')) return (Class as any).componentName;
  if (Object.hasOwnProperty.call(Class, 'className')) return (Class as any).className;
  if (Object.hasOwnProperty.call(Class, 'name')) return Class.name;
  return String(Class);
}

// == Component initialization constraint ==
const COMPONENTS_STORAGE_KEY = 'hpe.components';

function markAsInitialized(Class: HPEComponent, $el: JQuery<HTMLElement>) {
  const set = $el.data(COMPONENTS_STORAGE_KEY) || new WeakSet();
  set.add(Class);
  $el.data(COMPONENTS_STORAGE_KEY, set);
}
function alreadyInitialized(Class: HPEComponent, $el: JQuery<HTMLElement>) {
  const set = $el.data(COMPONENTS_STORAGE_KEY);
  return set && set.has(Class);
}
