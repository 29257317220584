import {debug, error} from 'core/log';
import {memoizeFn} from '@exadel/esl/modules/esl-utils/misc/memoize';
import {loadScript} from '@exadel/esl/modules/esl-utils/dom/script';
import {tryUntil} from '@exadel/esl/modules/esl-utils/async/promise';

const ID = 'twitter-wjs';
const SRC = '//platform.twitter.com/widgets.js';
const TRY_COUNT = 80;

/**
 * Request Twitter API if it is not presented
 * @event 'twitter-api:loaded' on the window
 * @returns {Promise}
 * */
export const requestTwitterAPI = memoizeFn(
  () => loadScript(ID, SRC)
    .then(() => tryUntil(() => window.twttr && window.twttr.widgets, TRY_COUNT))
    .then(() => new Promise<void>((resolve) => window.twttr.ready(resolve)))
    .then(() => {
      window.dispatchEvent(new CustomEvent('twitter-api:loaded'));
      debug('TwitterWidget: Widget loaded successfully');
      return window.twttr;
    })
    .catch((e) => {
      error('TwitterWidget: Could not initialize ', e);
      return Promise.reject(e);
    })
);

/**
 * Request Twitter API if it is not presented and call refresh
 * @returns {Promise}
 * */
export const refreshTwitter = () => requestTwitterAPI().then((api) => {
  api.widgets.load();
  return api;
});

export default requestTwitterAPI;

declare global {
  interface Window {
    twttr: {
      ready(cb: () => void): void;
      widgets: {
        load(): void;
      };
    };
  }
}
