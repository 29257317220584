/**
 * Init a11y for checklist rte plugin
 * @param target {JQuery<HTMLElement>} container which contains check items
 * */
export function a11yCheckItems(target: JQuery<HTMLElement>): void {
  const checkItems = target.find('.check-item');
  checkItems.each((i, item) => {
    item.setAttribute('role', 'checkbox');
    item.setAttribute('aria-checked', String(item.classList.contains('checked')));
    item.setAttribute('aria-readonly', 'true');
  });
}
