var MODULE_ALIAS = 'core/video'; 
import View from 'core/view';
import {isA11yMode} from 'core/helpers/focus';
import {ESC, SPACE} from '@exadel/esl/modules/esl-utils/dom/keys';

const DEFAULTS = Object.assign({}, View.defaults, {
  videoTrigger: '.js-video-trigger',
  closeButton: '.js-video-close',
  playerWrapper: '.js-player-wrapper',
  animationClass: 'video-showing-animation',
  embeddedPlayerSel: '.embedded-player',
  closeOnBreakpoints: ''
});

class Video extends View { 
        static className = MODULE_ALIAS + '#Video';
         
      

  static VIDEO_SHOW_ANIMATION_LENGTH = 400;
  static VIDEO_HIDE_ANIMATION_LENGTH = 300;
  static VIDEO_TRIGGER_ANIMATION_DELAY_LENGTH = 250;

  static defaults = DEFAULTS;
  static dataAttrs = 'attribute-video';

  init() {
    this.closeButton = this.find(this.options.closeButton);
    this.playButton = this.find(this.options.videoTrigger);
    this.playerWrapper = this.find(this.options.playerWrapper);
    this.player = this.playerWrapper.find(this.options.embeddedPlayerSel)[0];
    this.isPlayerShowed = false;

    this.bindEvents('{window} on:hpe.stop.embedded.videos', () => this.pause(false));

    if (this.options.closeOnBreakpoints) {
      this.closeOnBreakpoints = this.options.closeOnBreakpoints.split(',').map((item) => item.trim());
      this.bindEvents('{window} on:breakpointchange', this.onBreakpointChange);
    }
  }

  onBreakpointChange(bp) {
    if (this.closeOnBreakpoints.indexOf(bp) !== -1) {
      this.pause(false);
    }
  }

  'on:stop'() {
    this.stop(false);
  }

  '{videoTrigger} on:click'() {
    this.play();
  }
  '{closeButton} on:click'() {
    this.pause();
  }

  '{playerWrapper} on:esl:media:play'() {
    this._setFocusToCloseBtn();
    this.playButton.removeClass('off');
  }
  '{playerWrapper} on:esl:media:paused'() {
    this._setFocusToCloseBtn();
  }
  '{playerWrapper} on:esl:media:ended'() {
    setTimeout(() => {
      this.pause();
    }, 1000);
  }
  '{playerWrapper} on:esl:media:mangedpause'() {
    this.pause(false);
  }

  'on:shared:play'() {
    this.playSafe();
  }

  // a11y
  'on:keydown'(e) {
    switch (e.key) {
      case ESC:
        this.pause();
        break;
      case SPACE:
        e.preventDefault();
        this.toggle();
    }
  }
  '{document} on:fullscreenchange'() {
    this._setFocusToCloseBtn();
  }
  '{document} on:webkitfullscreenchange'() {
    this._setFocusToCloseBtn();
  }
  '{document} on:mozfullscreenchange'() {
    this._setFocusToCloseBtn();
  }

  play() {
    if (this.player) {
      this.$el.triggerHandler('HPE:VIDEO:PLAY:CLICKED');
      this.showPlayer();
      this.playButton.addClass('off');
      this.player.play(true);
      this._setFocusToCloseBtn();
    }
  }

  playSafe() {
    customElements.whenDefined('smart-media').then(() => this.play());
  }

  pause(setFocus) {
    this.player && this.player.pause();
    this.hidePlayer(setFocus);
  }

  toggle() {
    !this.isPlayerShowed ? this.play() : this.player.toggle();
  }

  stop(setFocus) {
    this.player && this.player.stop();
    this.hidePlayer(setFocus);
  }

  // Animation functions
  showPlayer() {
    this.$el.addClass(this.options.animationClass); // animation inside CSS
    this.playerWrapper.removeClass('hide');
    setTimeout(() => {
      this.playerWrapper.animate({opacity: 1}, Video.VIDEO_SHOW_ANIMATION_LENGTH);
      this.closeButton.show(Video.VIDEO_SHOW_ANIMATION_LENGTH);
      this.playButton.addClass('hidden');
      this.closeButton.focus(); // to keep focus, regardless of the a11y mode
    }, 250);
    this.$el.trigger('HPE.VIDEO.SHOW');

    this.isPlayerShowed = true;
  }

  hidePlayer(setFocus = true) {
    if (!this.isPlayerShowed) return;
    this.playerWrapper.animate({opacity: 0}, Video.VIDEO_HIDE_ANIMATION_LENGTH);
    this.closeButton.hide(Video.VIDEO_HIDE_ANIMATION_LENGTH);
    setTimeout(() => {
      this.playerWrapper.addClass('hide');
      this.playButton.removeClass('hidden off');
      setFocus && this.playButton.focus();
      this.$el.removeClass(this.options.animationClass);
    }, Video.VIDEO_TRIGGER_ANIMATION_DELAY_LENGTH);
    this.$el.trigger('HPE.VIDEO.HIDE');
    this.isPlayerShowed = false;
  }

  _setFocusToCloseBtn(time = 300) {
    if (!isA11yMode()) return;
    setTimeout(() => {
      this.closeButton.focus();
    }, time);
  }
}

export default Video;

;exports.default.componentName = MODULE_ALIAS;