import {isLanguageCode} from 'core/helpers/config';
import {getAnchorNavHeight, getCollateralStickyBarHeight} from 'core/helpers/scroll';
import {getStickyHeaderHeight} from 'hpe-gn/hpe-gn.utils';

import {ESLNote} from '@exadel/esl/modules/esl-footnotes/core';
import {ESLTooltipActionParams} from '@exadel/esl/modules/esl-tooltip/core';
import {ESLEventUtils} from '@exadel/esl/modules/esl-utils/dom/events';
import {ESLMediaQuery} from '@exadel/esl/modules/esl-media-query/core';
import {ESLTraversingQuery} from '@exadel/esl/modules/esl-traversing-query/core';
import {prop, attr, boolAttr, memoize} from '@exadel/esl/modules/esl-utils/decorators';
import {promisifyTimeout} from '@exadel/esl/modules/esl-utils/async/promise';
import {unwrap} from '@exadel/esl/modules/esl-utils/misc/array';

import type {ESLToggleableActionParams} from '@exadel/esl/modules/esl-toggleable/core';

export interface HPENoteScrollConfig {
  $target: HTMLElement | Window;
  options: ScrollToOptions;
}

export class HPENote extends ESLNote {
  public static is = 'hpe-note';

  static get observedAttributes(): string[] {
    return [...ESLNote.observedAttributes, 'standalone-label'];
  }

  /** Event to request acknowledgment from {@link HPENote} instances */
  @prop('hpe:note:request') public FOOTNOTE_REQUEST_EVENT: string;
  /** Event to acknowledge {@link HPEFootnotes} instance about footnote */
  @prop('hpe:note:response') public FOOTNOTE_RESPONSE_EVENT: string;
  /** Event to acknowledge about footnote update */
  @prop('hpe:note:update') public FOOTNOTES_UPDATE_EVENT: string;

  /** Enables hpe:note:update event firing on every index update */
  @boolAttr() public trackUpdate: boolean;

  /** Hover event tracking media query. Default: `all` */
  @attr({defaultValue: 'not all'}) public trackHover: string;

  /** Delay for the scrolling & tooltip activation */
  @attr({defaultValue: '150'}) public showDelay: string;

  /** Scroll and container top offset */
  @attr({defaultValue: '20'}) public topOffset: string;

  /** Defines outer element (using {@link ESLTraversingQuery} syntax), that should be scrolled to reach the note (window by default) */
  @attr() public scrollContainer: string;

  protected init(): void {
    super.init();
    this.html = this.decode(this.html);
  }

  protected decode(text: string): string {
    try {
      return decodeURIComponent(text);
    } catch {
      return text;
    }
  }

  /** Localized index */
  public get renderedIndex(): string {
    const indexString = `${isLanguageCode('ja') ? '*' : ''}${this._index}`;
    return this.allowFootnotes ? indexString : this.standaloneLabel;
  }

  @memoize()
  public get $scrollContainer(): HTMLElement | null {
    const container = this.getClosestRelatedAttr('scroll-container') || this.scrollContainer;
    return container ? ESLTraversingQuery.first(container, this) as HTMLElement : null;
  }

  /**
   * HPE specific. Returns query, on which custom scroll container must be used.
   * Supported breakpoints should be defined via {@link ESLMediaQuery} syntax at the scroll container attribute.
   *  @example
   *  - hpe-note-allow-scroll-container="@+SM"
   */
  @memoize()
  protected get scrollContainerQuery(): ESLMediaQuery {
    const query = this.$scrollContainer.getAttribute(`${HPENote.is}-allow-scroll-container`) || 'all';
    return ESLMediaQuery.for(query);
  }

  @memoize()
  public get delay(): number {
    return +(this.getClosestRelatedAttr('show-delay') || this.showDelay);
  }

  protected attributeChangedCallback(attrName: string, oldVal: string, newVal: string): void {
    super.attributeChangedCallback(attrName, oldVal, newVal);
    if (attrName === 'standalone-label') {
      if (!this.standalone) return;
      this.innerHTML = this.renderedIndex;
    }
  }

  public get index(): number {
    return this._index;
  }

  public set index(value: number) {
    const isChanged = this._index !== value;
    this._index = value;
    this.innerHTML = this.renderedHTML;
    if (isChanged) this._onIndexUpdate();
  }

  protected _onIndexUpdate(): void {
    if (!this.trackUpdate) return;
    ESLEventUtils.dispatch(this, this.FOOTNOTES_UPDATE_EVENT);
  }

  /** Activates note */
  public activate(): void {
    this.hideTarget();
    this.$$fire('esl:show:request');
    promisifyTimeout(this.delay)
      .then(() => this.scrollToNote())
      .then(() => this.$target.open || this.showTarget());
  }

  /** Uses {@link IntersectionObserver} to detect the note visibility and release the promise */
  public scrollToNote(): Promise<boolean> {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver((entries) => {
        const entry = unwrap(entries);
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          resolve(true);
        }
      }, {
        threshold: [0.9]
      });
      const {$target, options} = this.scrollConfig;
      $target.scrollTo(options);
      observer.observe(this);
    });
  }

  /** Returns top offset, taking into account global/anchor nav height */
  protected get adaptedTopOffset(): number {
    return +this.topOffset + Math.max(getAnchorNavHeight(), getStickyHeaderHeight(), getCollateralStickyBarHeight());
  }

  /** Returns scroll configuration considering the defined container */
  protected get scrollConfig(): HPENoteScrollConfig {
    const isScoped = this.$scrollContainer && this.scrollContainerQuery.matches;
    const base = this.getBoundingClientRect().top;
    const scroll = !isScoped ?
      window.scrollY :
      this.$scrollContainer.scrollTop - this.$scrollContainer.getBoundingClientRect().top;

    return {
      $target: isScoped ? this.$scrollContainer : window,
      options: {
        top: base + scroll - this.adaptedTopOffset,
        behavior: 'smooth'
      }
    };
  }

  /** Merge params to pass to the note */
  protected mergeToggleableParams(this: HPENote, ...params: ESLToggleableActionParams[]): ESLTooltipActionParams {
    return super.mergeToggleableParams({
      ...params,
      extraClass: 'typo4 rich-text-container body-copy-small',
      offsetContainer: [0, this.adaptedTopOffset]
    });
  }
}
