import {memoize} from '@exadel/esl/modules/esl-utils/decorators/memoize';
import {ESLIntersectionEvent, ESLIntersectionTarget, SyntheticEventTarget} from '@exadel/esl/modules/esl-utils/dom';
import {ESLEventUtils, ESLResizeObserverTarget} from '@exadel/esl/modules/esl-utils/dom/events';
import {decorate, listen} from '@exadel/esl/modules/esl-utils/decorators';
import {isBot} from '@exadel/esl/modules/esl-utils/environment/device-detector';
import {loadScript} from '@exadel/esl/modules/esl-utils/dom/script';
import {debounce} from '@exadel/esl/modules/esl-utils/async/debounce';

import {Logger} from 'core/log';
import {isAEMAuthorMode} from 'core/helpers/aem';
import {getConfig} from 'core/helpers/config';

const {debug, error} = Logger.for('[HPE PrivacyBannerProxy]');

/**
 * API provider for the Privacy Banner
 *
 * @event boundaryresize - throws when the PB is resized (lazy, i.e. creates ResizeObserver and other stuff only when the first listener is added)
 * @event change - throws when the PB state is changed
 */
export class PrivacyBannerProxy extends SyntheticEventTarget {
  public static readonly BANNER_SELECTOR = '#consent_blackbar, #hpe_privacy_banner_container, #hpealertcomp_container';
  public static readonly OBSERVED_EVENTS = [
    'HPEPRIVACYBANNER.CONFIGCHANGE',
    'HPEPRIVACYBANNER.READY',
    'HPEALERTBANNER.READY',
    'HPEPRIVACYBANNER.OPEN',
    'HPEALERTBANNER.OPEN',
    'HPEPRIVACYBANNER.CLOSE',
    'HPEALERTBANNER.CLOSE'
  ];

  @memoize()
  static get instance(): PrivacyBannerProxy {
    return new PrivacyBannerProxy();
  }

  static initialize(): void {
    PrivacyBannerProxy.instance.load();
  }

  public loaded: boolean = false;
  public intersectionRatio: number = 0;

  public get $banner(): HTMLElement {
    return document.querySelector(PrivacyBannerProxy.BANNER_SELECTOR);
  }

  public get height(): number {
    return this.visible ? this.visibleBanner.getBannerHeight() : 0;
  }

  public get visible(): boolean {
    return !!this.visibleBanner;
  }

  protected get visibleBanner(): PrivacyBanner | null {
    if (window.HPEPRIVACYBANNER?.isBannerVisible?.()) return window.HPEPRIVACYBANNER;
    if (window.HPEALERTBANNER?.isBannerVisible?.()) return window.HPEALERTBANNER;
    return null;
  }

  protected constructor() {
    super();
    ESLEventUtils.subscribe(this);
  }

  protected async load(): Promise<void> {
    if (this.loaded) return;
    debug('Initialization...');
    if (isBot) {
      debug('Blocked for a Google bot');
      return;
    }

    if (isAEMAuthorMode()) {
      debug('Was disabled for authors');
      return;
    }

    const url = getConfig('privacyBannerScript');
    if (!url) {
      debug('"HPE_CONFIG.privacyBannerScript" is not configured');
      return;
    }

    try {
      await loadScript('privacy-banner-script', url);
      this.loaded = true;
      debug('Script "%s" was loaded', url);
    } catch {
      error('Unknown HTTP error with "%s"', url);
    }
  }

  @decorate(debounce, 200)
  protected _onSizeChange(): void {
    this.dispatchEvent(new CustomEvent('boundaryresize'));
  }

  @listen({
    event: () => PrivacyBannerProxy.OBSERVED_EVENTS.join(' '),
    target: document
  })
  protected _onChange(e: Event): void {
    debug(`Received ${e.type} event`);
    this.handleChange();
  }

  @decorate(debounce, 100)
  protected handleChange(): void {
    if (this.visible) {
      ESLEventUtils.subscribe(this, this._onResize);
      ESLEventUtils.subscribe(this, this._onIntersect);
    } else {
      ESLEventUtils.unsubscribe(this, this._onResize);
      ESLEventUtils.unsubscribe(this, this._onIntersect);
      this.intersectionRatio = 0;
      this._onSizeChange();
    }
    debug('Dispatching internal "change" event');
    this.dispatchEvent(new CustomEvent('change'));
  }

  @listen({
    event: 'resize',
    target: (that: PrivacyBannerProxy) => ESLResizeObserverTarget.for(that.$banner),
    condition: (that: PrivacyBannerProxy) => that.visible
  })
  protected _onResize(): void {
    this._onSizeChange();
  }

  @listen({
    event: 'intersects',
    target: (that: PrivacyBannerProxy) => ESLIntersectionTarget.for(that.$banner, {threshold: [0.01, 0.25, 0.5, 0.75, 0.99]}),
    condition: (that: PrivacyBannerProxy) => that.visible
  })
  protected _onIntersect(e: ESLIntersectionEvent): void {
    this.intersectionRatio = e.intersectionRatio;
    this._onSizeChange();
  }
}

export default PrivacyBannerProxy;

declare global {
  interface PrivacyBanner {
    isBannerVisible: () => boolean;
    getBannerHeight: () => number;
  }

  interface Window {
    HPEALERTBANNER?: PrivacyBanner;
    HPEPRIVACYBANNER?: PrivacyBanner;
  }
}
