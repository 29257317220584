import 'jquery';
import 'jquery-touchswipe';

import {ESLMediaQuery} from '@exadel/esl/modules/esl-media-query/core';
import {bind, memoize} from '@exadel/esl/modules/esl-utils/decorators';
import {afterNextRender} from '@exadel/esl/modules/esl-utils/async/raf';
import {ARROW_LEFT, ARROW_RIGHT} from '@exadel/esl/modules/esl-utils/dom/keys';

import {View, ViewOptions} from 'core/view';
import {debug} from 'core/log';
import {isA11yMode} from 'core/helpers/focus';
import {hasTouchEvents} from 'core/legacy/touchevents';
import {getExtremeItemsProp} from 'core/helpers/dom-sizes';
import {dispatchNativeEvent, unwrap} from 'core/helpers/dom';
import {getTheme, reverseTheme, setTheme, Theme, ThemeFormatter} from 'core/helpers/themes';

export const SWIPE_EVENT = 'swipe-event';

export const SLIDE_THEME_FORMATTER: ThemeFormatter = (theme: string) => `slide-${theme}`;

export interface SlideCarouselOptions extends ViewOptions {
  loop: boolean;
  autoplay: boolean;
  autoplayInterval: number;
  fixAutoHeight: boolean;
  stopPropagationOnControlsClick: boolean;
  touchevents: boolean;
  propagateTheme: boolean;
  refreshCtaGroup: boolean;
  dotLabel: string;

  startIndex: number;
  transitionTime: number;

  navSel: string;
  itemSel: string;
  activeSel: string;
  touchAriaSel: string;
  slideAriaSel: string;
  noItemsClass: string;
  oneItemClass: string;
  enableControlsClass: string;
}

type Direction = 'prev' | 'next';

export interface SlideCarouselEvent<
  TDelegateTarget = any,
  TData = any,
  TCurrentTarget = any,
  TTarget = any
> extends JQuery.TriggeredEvent<TDelegateTarget, TData, TCurrentTarget, TTarget> {
  direction: Direction;
  noFocusReset: boolean;
  relatedTarget: HTMLElement;
}

interface VisibleSlideInfo {
  $el: JQuery<HTMLElement>;
  classes: string[];
}

export class SlideCarousel<TOptions extends SlideCarouselOptions = SlideCarouselOptions> extends View<TOptions> {
  static attrsOptionsMap: Record<string, string> = {
    '.fix-autoheight': 'fixAutoHeight'
  };

  static dataAttrs = 'slider';

  static defaults: SlideCarouselOptions = Object.assign({}, View.defaults, {
    loop: true,
    autoplay: false,
    autoplayInterval: 10000,
    fixAutoHeight: false,
    stopPropagationOnControlsClick: true,
    touchevents: true,
    propagateTheme: false,
    refreshCtaGroup: false,
    dotLabel: 'Carousel page',
    layout3items: 'not all',

    itemSel: '.item',
    activeSel: '.active',

    touchAriaSel: '.slide-carousel-inner',
    slideAriaSel: '.slide-carousel-inner',

    navSel: '.slide-carousel-dots:not(.slide-carousel .slide-carousel .slide-carousel-dots)',

    noItemsClass: 'no-items',
    oneItemClass: 'one-item',
    enableControlsClass: 'controls',
    atFirstClass: 'at-first',
    atLastClass: 'at-last',

    transitionTime: 350,
    startIndex: 0
  });

  static readonly directions: Direction[] = ['prev', 'next'];

  /** Singleton MutationObserver instance to track content changes */
  @memoize()
  protected static get mutationObserver() {
    return new MutationObserver((mutations) => {
      const mutationTargets = new Set<EventTarget>();
      [].forEach.call(mutations, (mutation: MutationRecord) => mutationTargets.add(mutation.target));
      mutationTargets.forEach((target) => {
        target.dispatchEvent(new CustomEvent('mutationdetected', {bubbles: true}));
      });
    });
  }

  protected _sliding: boolean;
  protected _timerId: number | null;
  protected _focusTimeout: number | null;
  protected _lastFocused: HTMLElement | undefined;

  @memoize()
  protected get layout3itemsQuery(): ESLMediaQuery {
    return ESLMediaQuery.for(this.options.layout3items);
  }

  @memoize()
  public get $innerArea(): JQuery<HTMLElement> {
    return this.find(this.options.slideAriaSel);
  }

  /** Slides JQuery collection (memoized) */
  @memoize()
  public get $items() {
    return this.$el.find(this.options.itemSel);
  }

  /** Active slide JQuery element */
  public get $active() {
    return this.$items.filter(this.options.activeSel);
  }

  /** Slides count */
  public get size() {
    return this.$items.length;
  }

  /** Slide navigation dots collection (memoized) */
  @memoize()
  public get $navDots() {
    return this.$el.find(this.options.navSel);
  }

  /** Verifies that carousel on "slide 3 items" layout and carousel size is 3 and more items */
  protected get isAllowedLayout3items(): boolean {
    return this.layout3itemsQuery.matches && this.size > 2;
  }

  public getItemIndex(item?: HTMLElement | JQuery<HTMLElement>) {
    return this.$items.index(item || this.$active);
  }

  public getItemForDirection(direction: Direction, active: JQuery<HTMLElement>): JQuery<HTMLElement> {
    const activeIndex = this.getItemIndex(active);
    const willWrap = (String(direction) === 'prev' && activeIndex === 0)
      || (String(direction) === 'next' && activeIndex === (this.$items.length - 1));
    if (willWrap && !this.options.loop) return active;
    const delta = String(direction) === 'prev' ? -1 : 1;
    const itemIndex = (activeIndex + delta) % this.$items.length;
    return this.$items.eq(itemIndex);
  }

  /** Move to the passed carousel position. Normalize values */
  public to(pos: number, direction?: Direction, noFocusReset = false): boolean {
    if (pos > (this.$items.length - 1) || pos < 0) return false;

    const activeIndex = this.getItemIndex(this.$active);
    if (activeIndex === pos) return false;

    if (this._sliding) {
      this.$el.one('sc.after.slide.change', () => this.to(pos));
      return true;
    }
    this.slide(direction || (pos > activeIndex ? 'next' : 'prev'), this.$items.eq(pos), noFocusReset);
    return true;
  }

  /** Move to the next slide (content order) */
  public next(noFocusReset = false) {
    if (this._sliding) return;
    return this.slide('next', null, noFocusReset);
  }

  /** Move to the previous slide (content order) */
  public prev(noFocusReset = false) {
    if (this._sliding) return;
    return this.slide('prev', null, noFocusReset);
  }

  protected navDotBuilder(index: number, analyticPrefix: string, targetId: string) {
    const attrs = [];
    attrs.push(`data-slide-to="${index}"`);
    attrs.push(`aria-label="${this.options.dotLabel} ${index + 1}"`);
    targetId && attrs.push(`aria-controls="${targetId}"`);
    analyticPrefix && attrs.push(`data-analytics-region-id="${analyticPrefix}"`);
    return `<button role="tab" class="dot-nav" ${attrs.join(' ')}> </button>`;
  }

  protected rebuildNavDots() {
    const slides = this.$items.toArray();
    const targetId = this.$innerArea.attr('id');
    const regionIdPrefix = this.$navDots.data('analytics-region-id-prefix');
    const dots = slides.map((slide, i) => this.navDotBuilder(i, regionIdPrefix, targetId));
    this.$navDots.html(dots.join(''));
    this.$navDots.attr('role', this.$navDots.attr('role') || 'tablist');
  }

  protected updateNavDots(index?: number) {
    if (this.$navDots.length) {
      const focusInside = !!this.$navDots.find(document.activeElement).length;
      // Normalize index
      if (typeof index !== 'number') {
        index = this.getItemIndex(this.$active);
      }
      // Validate dots control
      let $dots = this.$navDots.children();
      if ($dots.length !== this.size && this.size >= 1) {
        this.rebuildNavDots();
        $dots = this.$navDots.children();
      }

      const $activeDot = $dots.eq(index);

      // Set active dot
      $dots.removeClass('active').attr('aria-selected', 'false');
      $activeDot.addClass('active').attr('aria-selected', 'true');

      // Move focus if it was inside
      if (focusInside && $activeDot.length) {
        $activeDot.focus();
        this._lastFocused = $activeDot.get(0);
      }
    }
  }

  public updateTheme(slide: HTMLElement | JQuery = this.$active) {
    if (!this.options.propagateTheme) return;
    this.applyTheme(getTheme(slide));
  }
  protected applyTheme(theme: Theme | null): void {
    setTheme(this.$el, reverseTheme(theme), SLIDE_THEME_FORMATTER);
  }

  /** Preform slide animation with passed params */
  public slide(type: Direction, next?: JQuery<HTMLElement>, noFocusReset = false): void {
    const $active = this.$active;
    const $next = next || this.getItemForDirection(type, $active);
    const direction = String(type) === 'next' ? 'left' : 'right';
    const relatedTarget = unwrap($next);
    const eventInfo = {relatedTarget, direction, noFocusReset};

    // if next is active - nothing to do
    if ($next.hasClass('active')) {
      this._sliding = false;
      return;
    }

    // Trigger pre change slide event
    const eventBefore = $.Event('sc.before.slide.change', eventInfo);
    this.$el.trigger(eventBefore);

    // If prevented stop changing
    if (eventBefore.isDefaultPrevented()) return;

    // Activate changing mutex
    this._sliding = true;

    const index = this.getItemIndex($next);
    this.updateNavDots(index);
    this.checkPositionClass(index);

    // Prepare event
    const endEvent = $.Event('sc.after.slide.change', eventInfo);
    $next.addClass(type);

    const visibleSlides: VisibleSlideInfo[] = [];
    if (this.isAllowedLayout3items) {
      // for both visible neighbors adds direction class and create a slides list to remove classes from them on the transition end

      SlideCarousel.directions.forEach((dir) => {
        const $visible = this.getItemForDirection(dir, $active);
        visibleSlides.push({
          $el: $visible,
          classes: [`visible-${dir}`]
        });
      });
      if (this.$items.length > 3) {
        const classes = [`visible-${type}-${type}`];
        const $visible = this.getItemForDirection(type, $next).addClass(classes);
        visibleSlides.push({
          $el: $visible,
          classes
        });
      }
    }

    +$next[0].offsetWidth; // force reflow

    $active.addClass(direction);
    $next.addClass(direction);
    visibleSlides.forEach((visibleSlide) => {
      visibleSlide.$el.addClass(direction);
      visibleSlide.classes.push(direction);
    });

    this.onPrepareNextSlide($next);

    $active
      .one('bsTransitionEnd', () => {
        $next.removeClass([type, direction]).addClass('active');
        $active.removeClass(['active', direction]);
        $next.attr('aria-hidden', 'false');
        $active.attr('aria-hidden', 'true');
        if (this.isAllowedLayout3items) {
          // removes classes from both visible neighbors
          visibleSlides.forEach((visibleSlide) => visibleSlide.$el.removeClass(visibleSlide.classes));
          // adds class to new visible neighbors
          this.toggleVisibleNeighborClass(true);
        }
        this._sliding = false;
        setTimeout(() => this.$el.trigger(endEvent), 0);
      })
      .emulateTransitionEnd(this.options.transitionTime);
  }

  // Fix slides height
  public updateSliderHeight() {
    if (!this.options.fixAutoHeight) return;
    this.$innerArea.height('');
    this.$innerArea.height(getExtremeItemsProp(this.$items));
    dispatchNativeEvent(this.$innerArea, 'esl:refresh'); // Notify ESL about major content updates
  }

  public checkSlideCount() {
    this.toggleClass(this.options.noItemsClass, this.size === 0);
    this.toggleClass(this.options.oneItemClass, this.size === 1);
    this.toggleClass(this.options.enableControlsClass, this.size > 1);
  }

  public checkPositionClass(index?: number) {
    if (typeof index !== 'number') {
      index = this.getItemIndex(this.$active);
    }

    this.toggleClass(this.options.atFirstClass, index === 0);
    this.toggleClass(this.options.atLastClass, index === this.size - 1);
  }

  public init() {
    if (!this.$active.length) {
      this.$items.eq(this.options.startIndex).addClass('active');
    }

    this.checkSlideCount();
    this.updateNavDots();
    this.checkPositionClass();
    this.updateA11y();
    this.updateTheme();

    if (this.options.touchevents && hasTouchEvents && this.$el.swipe) {
      this.$el.find(this.options.touchAriaSel).swipe({
        swipeLeft: this.onTouchSwipe.bind(this, 'left'),
        swipeRight: this.onTouchSwipe.bind(this, 'right'),
        allowPageScroll: 'vertical'
      });
    }
    if (this.$innerArea.length) {
      SlideCarousel.mutationObserver.observe(this.$innerArea[0], {
        childList: true
      });
    }

    if (this.options.fixAutoHeight) {
      this.bindEvents('{window} on:resize', this.updateSliderHeight);
      this.updateSliderHeight();
    }

    if (this.options.autoplay) {
      this.initAutoplay();
    }

    this.onLayout3itemsChange();
    this.layout3itemsQuery.addEventListener(this.onLayout3itemsChange);
  }

  protected initAutoplay() {
    this.bindEvents('on:focusin', this.onFocusin);
    this.bindEvents('on:focusout', this.onFocusout);
    this.bindEvents('on:mouseenter', this.onMouseenter);
    this.bindEvents('on:mouseleave', this.onMouseleave);
    this._timerId = null;
    !this.$el.is(':hover') && this.startAutoplay();
  }

  protected toggleVisibleNeighborClass(state: boolean) {
    const {$active} = this;
    SlideCarousel.directions.forEach((direction) => {
      const $item = this.getItemForDirection(direction, $active);
      if (!$item.is($active)) {
        $item.toggleClass(`visible-${direction}`, state);
      }
    });
  }

  onDestroy() {
    this.stopAutoplay();
    this.layout3itemsQuery.removeEventListener(this.onLayout3itemsChange);
    super.onDestroy();
  }

  /** Start carousel auto slide change */
  public startAutoplay() {
    if (this._timerId === null) {
      this._timerId = window.setInterval(() => this.next(true), this.options.autoplayInterval);
    }
  }

  /** Stop carousel auto slide change */
  public stopAutoplay() {
    if (this._timerId !== null) {
      window.clearInterval(this._timerId);
      this._timerId = null;
    }
  }

  /**
   * Reinitialize hook happens on carousel content changes
   * Used in personalization content manipulations
   */
  public reinitialize() {
    debug('Carousel: changes detected, carousel refreshed');
    // Invalidate cached values
    memoize.clear(this, ['$items', '$navDots']);

    this.checkSlideCount();
    this.updateNavDots();
    this.checkPositionClass();
    this.updateA11y();
    this.updateTheme();
    this.onReinitialize();
    this.updateSliderHeight();
  }

  // Events
  'on:reinitialze'() {
    this.reinitialize();
  }

  'on:updateHeight refresh'() {
    this.updateSliderHeight();
  }

  '{window} on:fonts.loaded'() {
    this.updateSliderHeight();
  }

  '.slide-carousel-inner on:mutationdetected'() {
    this.reinitialize();
  }

  'on:keydown'(e: JQuery.KeyDownEvent) {
    if (/input|textarea/i.test(e.target.tagName)) return;
    switch (e.key) {
      case ARROW_LEFT:
        this.prev();
        break;
      case ARROW_RIGHT:
        this.next();
        break;
      default:
        return;
    }
    e.preventDefault();
  }

  'on:sc.after.slide.change'(e: SlideCarouselEvent) {
    this.onSlideChanged(e);
  }

  'on:sc.before.slide.change'(e: SlideCarouselEvent) {
    this.onBeforeSlideChange(e);
  }

  '[data-slide-to] on:click'(e: JQuery.ClickEvent) {
    this.onControlElementClick(e);
  }

  '{itemSel} on:esl:show:request'(e: JQuery.TriggeredEvent) {
    const activeIndex = this.getItemIndex(e.currentTarget);
    this.to(activeIndex);
  }

  protected onReinitialize() {}

  @bind
  protected onLayout3itemsChange() {
    if (this.size < 3) return;
    this.toggleVisibleNeighborClass(this.layout3itemsQuery.matches);
    this.$innerArea.toggleClass('layout-3-items', this.layout3itemsQuery.matches);
  }

  protected onFocusin(e: JQuery.FocusInEvent) {
    if (this.options.autoplay && isA11yMode()) {
      this.stopAutoplay();
    }
  }

  protected onFocusout(e: JQuery.FocusOutEvent) {
    if (this.options.autoplay && isA11yMode()) {
      if ($(e.relatedTarget).closest(this.$el).length === 0) {
        this.startAutoplay();
      }
    }
  }

  protected onMouseenter(e: JQuery.MouseEnterEvent) {
    this.stopAutoplay();
  }

  protected onMouseleave(e: JQuery.MouseLeaveEvent) {
    this.startAutoplay();
  }

  protected onControlElementClick(e: JQuery.ClickEvent) {
    let val = e.currentTarget.getAttribute('data-slide-to');
    switch (val) {
      case 'next':
        this.next();
        break;
      case 'prev':
        this.prev();
        break;
      default:
        val = +val;
        (!isNaN(val)) && this.to(val);
        break;
    }
    // fixing problem of nested carousel, due to problem with tooltips hiding
    if (this.options.stopPropagationOnControlsClick && e.currentTarget.closest('.slide-carousel') !== this.$el.get(0)) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  protected onTouchSwipe(direction: 'left' | 'right') {
    this.$el.trigger(SWIPE_EVENT, direction);
    switch (direction) {
      case 'left':
        this.next();
        break;
      case 'right':
        this.prev();
        break;
    }
  }

  // Callback hooks
  /** Inner slide changed hook */
  protected onSlideChanged(e: SlideCarouselEvent) {
    this.$items.filter(`:not(${this.options.activeSel})`)
      .find('.js-embedded-video')
      .trigger('stop'); // Stop all videos

    this.updateA11y();
    (!e || !e.noFocusReset) && this.requestFocusReset();
  }

  /** Inner before slide change hook */
  protected onBeforeSlideChange(e: SlideCarouselEvent) {
    // Stored to check the reason for reset focus
    this._lastFocused = document.activeElement as HTMLElement;
    afterNextRender(() => this.updateTheme(e.relatedTarget));
  }

  /** Next slide pre-processing hook */
  protected onPrepareNextSlide($slide: JQuery<HTMLElement>) {
    // Fix for attract loop alignment
    dispatchNativeEvent(this.$el, 'esl:refresh');
  }

  // A11y utils
  /** @returns if the focus carousel specific control allowed */
  public get allowFocusReset(): boolean {
    if (!isA11yMode()) return false;
    return document.activeElement === this._lastFocused ||
      document.activeElement === null || document.activeElement === document.body;
  }

  /** Request reset focus to the slide */
  public requestFocusReset(timeout = 750): void {
    this._focusTimeout && window.clearTimeout(this._focusTimeout);
    this._focusTimeout = window.setTimeout(() => {
      if (this.allowFocusReset) {
        const {$active, $innerArea} = this;
        ($active.is('[tabindex]') ? $active : $innerArea).focus();
      }
      this._lastFocused = null;
    }, timeout);
  }

  /** Update a11ty attributes */
  public updateA11y(): void {
    const $active = this.$active;
    if (!$active.length) return;
    $active.attr('aria-hidden', 'false');
    if (!$active.attr('aria-label')) {
      $active.attr('aria-label', this.buildAriaLabel($active));
    }
  }

  /** Builds slide area ARIA label */
  protected buildAriaLabel($slide: JQuery<HTMLElement>): string | null {
    const ariaEls = $slide.find('[data-slide-aria]').toArray();
    const ariaText = ariaEls.map((el) => {
      const text = (el.textContent || '').trim();
      if (text === '' || /[.!?]$/.test(text)) return text;
      return text + '.';
    });
    return ariaText.join(' ') || null;
  }
}

export default SlideCarousel;
