import {ESLMixinElement} from '@exadel/esl/modules/esl-mixin-element/core';
import {attr, prop, listen, decorate, memoize} from '@exadel/esl/modules/esl-utils/decorators';
import {afterNextRender, rafDecorator} from '@exadel/esl/modules/esl-utils/async/raf';
import {isRelativeNode} from '@exadel/esl/modules/esl-utils/dom/traversing';

import type {ESLImage} from '@exadel/esl/modules/esl-image/core';

class HPEImgAccentMixin extends ESLMixinElement {
  static is = 'hpe-img-accent';

  @prop(0.06) public offsetSize: number;
  @attr({name: HPEImgAccentMixin.is}) public placement: string;

  @memoize()
  public get $image(): ESLImage | HTMLImageElement {
    return this.$host.querySelector('img, smart-image');
  }

  public get ratio(): number {
    if (!this.$image) return 0;
    if (this.$image instanceof HTMLImageElement) {
      return 100 * this.$image?.naturalHeight / this.$image?.naturalWidth;
    }
    return 100 * this.$image.originalHeight / this.$image.originalWidth;
  }

  protected override connectedCallback(): void {
    if (this.placement === 'none') return;
    super.connectedCallback();
    this.update();
    afterNextRender(() => this.update());
  }

  public update(): void {
    const {ratio} = this;
    const offset = this.$host.clientWidth * this.offsetSize;
    const sizeRel = 1 - this.offsetSize;

    this.$host.style.setProperty('--img-ratio', `${ratio.toFixed(2)}%`);
    this.$host.style.setProperty('--img-size-rel', `${sizeRel}`);
    this.$host.style.setProperty('--img-accent-size', `${offset}px`);
  }

  @listen({event: 'resize esl:refresh', target: window})
  @decorate(rafDecorator)
  protected onResize(e: Event): void {
    if (e.type === this.REFRESH_EVENT && !isRelativeNode(e.target as Node, this.$host)) return;
    this.update();
  }

  @listen({event: 'load error', target: (that: HPEImgAccentMixin) => that.$image})
  protected onImgLoad(): void {
    this.update();
  }
}

export default {
  initialize: () => HPEImgAccentMixin.register()
};
