const noop = (): any => undefined;
const NOCACHE_PARAM = window.location.href.indexOf('uinocache=') !== -1;

interface IStorage {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => string;
  removeItem: (key: string) => void;
}

const FAKE_STORAGE: IStorage = {
  setItem: noop,
  getItem: noop,
  removeItem: noop
};

export interface ICacheOptions {
  ns?: string;
  long?: boolean; // Save data after browser closing
  liveTime?: number; // Mins
}

const getBrowserStorage = (longTerm: boolean) => {
  if (NOCACHE_PARAM) {
    console.log('HPE: Cache Storage is disabled via "' + NOCACHE_PARAM + '" option');
    return FAKE_STORAGE;
  }
  try {
    return longTerm ? window.localStorage : window.sessionStorage;
  } catch {
    console.log('HPE: Cache Storage is disabled');
    return FAKE_STORAGE;
  }
};

export class Cache {
  public readonly ns: string = '';
  public readonly long: boolean = false; // Save data after browser closing
  public readonly liveTime: number = 15; // Mins

  private storage: IStorage = FAKE_STORAGE;

  constructor(options?: ICacheOptions) {
    Object.assign(this, options || {});
    this.storage = getBrowserStorage(this['long']);
  }

  buildKey(key: string) {
    return `${this.ns}#${key || ''}`;
  }

  get(key: string) {
    const storeKey = this.buildKey(key);
    const {t, v} = Cache.deserialize(this.storage.getItem(storeKey)) || {};
    if (t && t > Date.now()) return v;
    this.storage.removeItem(storeKey);
    return null;
  }

  set(key: string, value: any) {
    const storeKey = this.buildKey(key);
    this.storage.setItem(storeKey, Cache.serialize({
      t: Date.now() + this.liveTime * 60 * 1000,
      v: value
    }));
  }

  remove(key: string) {
    this.storage.removeItem(this.buildKey(key));
  }
  removeAll() {
    this.keys.forEach((key) => this.remove(key));
  }

  get keys() {
    const prefix = this.buildKey('');
    return Object.keys(this.storage)
      .filter((key) => key.indexOf(prefix) === 0)
      .map((key) => key.slice(prefix.length));
  }

  static sanitize() {
    const now = Date.now();
    const storage = getBrowserStorage(true);

    Object.keys(storage).forEach((key) => {
      if (key.indexOf('##') === -1) return;
      const {t} = Cache.deserialize(storage.getItem(key)) || {};
      if (!t || t <= now) storage.removeItem(key);
    });
  }

  static serialize(value: any) {
    return JSON.stringify(value);
  }
  static deserialize(value: string) {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }

  // Simple session cache
  static readonly session = new Cache({
    ns: 'userSession',
    liveTime: 60 * 24 * 7
  });
}

// CleanUp outdated long cache only one-time.
if (!Cache.session.get('longCacheCleaned')) { // cleanup each 7 days or new session
  setTimeout(Cache.sanitize, 10000);
  Cache.session.set('longCacheCleaned', true);
}

export default Cache;
