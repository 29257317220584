type URLEntry = {
  param: string;
  value: string | string[] | null;
};

export class URLSuffixes {

  _entries: URLEntry[];

  /**
   * Parse suffix string and create URLSuffix
   * @param {string} path - url suffix string
   */
  constructor(path: string = '') {
    this._entries = [];

    const suffixes = URLSuffixes.extractSuffix(path).split('/');
    if (suffixes.shift() !== '') return; // does not start with '/'

    while (suffixes.length) {
      let value, param = suffixes.shift();
      if (!param) return; // to handle cases with few '/' one after another

      const results = param.match(/page(\d+)/); // special param page
      if (results) {
        param = 'page';
        value = results[1];
      } else {
        value = suffixes.shift();
      }
      value = value ? decodeURIComponent(value).split('_') : null; // do we need return single value as array?

      this._entries.push({param, value});
    }
  }

  /**
   * Extract suffix from url path
   * @param {string} path
   * @returns {string}
   */
  static extractSuffix(path: string): string {
    if (!path || typeof path !== 'string') return '';

    const posHtml = path ? path.indexOf('.html') : -1;
    const res = (posHtml > -1) ? path.substring(posHtml + 5) : path;
    if (!res || res.substring(0, 1) !== '/') return '';

    return res.replace(/(#.*)$/, '').replace(/(\?.*)$/, '');
  }

  /**
   * Create new URLSuffixes object from url string or object (like appendAll)
   * @param {string|Object} fromValue
   * @returns {URLSuffixes}
   */
  static from(fromValue: any = ''): URLSuffixes {
    if (fromValue && !Array.isArray(fromValue) && typeof fromValue === 'object') {
      const us = new URLSuffixes();
      us.appendAll(fromValue);
      return us;
    } else if (fromValue && typeof fromValue === 'string') {
      return new URLSuffixes(fromValue);
    }
    return new URLSuffixes();
  }

  /**
   * Find entry by param name
   * @private
   * @param name
   * @returns entry
   */
  _find(name: string): URLEntry {
    return this._entries.find((p) => {
      return p.param === name;
    });
  }

  /**
   * Set param value
   * @private
   * @param param
   * @param value
   */
  _set(param: string, value: string | string[]): void {
    const entry = this._find(param);
    entry ? (entry.value = value) : this._entries.push({param, value});
  }

  /**
   * Append param value
   * @private
   * @param param
   * @param value
   */
  _append(param: string, value: string | string[]): void {
    const entry = this._find(param);
    entry ? (value as string[]).forEach((item) => (entry.value as string[]).push(item)) : this._entries.push({
      param,
      value
    });
  }

  /**
   * Normalize entry value to an array
   * @private
   * @param value
   * @returns {Array}
   */
  _normalize(value: string | string[]): string[] {
    return Array.isArray(value) ? value : [value];
  }

  /**
   * Returns entry value as Array or primitive type if its possible
   * @private
   * @param {Array} value
   * @param {Boolean} asPrimitives
   * @returns {Array|string}
   */
  _simplify(value: string | string[], asPrimitives: boolean): string | string[] {
    return (asPrimitives && value.length === 1) ? value[0] : value;
  }

  _all(func: any, fromValue: any, defaults: any): URLSuffixes {
    if (fromValue && !Array.isArray(fromValue) && typeof fromValue === 'object') {
      Object.keys(fromValue).forEach((key: string) => {
        if (this._normalize(fromValue[key]).join() !== (defaults[key] && this._normalize(defaults[key]).join())) {
          func(key, fromValue[key]);
        }
      });
    }
    return this;
  }

  /**
   * Check if specified param exists
   * @param {string} name - param name
   * @returns {boolean}
   */
  has(name: string): boolean {
    return this._entries.some((el) => el.param === name);
  }

  /**
   * Append param
   * @param {string} name - param name
   * @param {string|Array} value - params value
   */
  append(name: string, value: string | string[] | null): void {
    name && this._append(name, this._normalize(value));
  }

  /**
   * Append parameters values from specified object
   *
   * @param {Object} appendValue - object with parameters and their values
   * @param {Object} defaults - the default value of parameters (will be excluded if values are equivalent)
   * @returns {URLSuffixes}
   */
  appendAll(appendValue: any, defaults: any = {}): URLSuffixes {
    return this._all(this.append.bind(this), appendValue, defaults);
  }

  /**
   * Append param
   * @param {string} name - param name
   * @param {string|Array} value - params value
   */
  set(name: string, value: string | string[]) {
    name && this._set(name, this._normalize(value));
  }

  /**
   * Set parameters values from specified object
   *
   * @param {Object} setValue - object with parameters and their values
   * @param {Object} defaults - the default value of parameters (will be excluded if values are equivalent)
   * @returns {URLSuffixes}
   */
  setAll(setValue: any, defaults: any = {}): URLSuffixes {
    return this._all(this.set.bind(this), setValue, defaults);
  }

  /**
   * Get value of param (first if there are several values)
   * @param {string} name - param name
   * @returns {string}
   */
  get(name: string): string {
    const entry = this._find(name);

    return entry ? entry.value && entry.value[0] : undefined;
  }

  /**
   * Get all values of param
   * @param {string} name - param name
   * @returns {Iterable}
   */
  getAll(name: string): Iterable<string> {
    const entry = this._find(name);

    return entry ? entry.value : undefined;
  }

  /**
   * Get all params
   * @returns {Iterable}
   */
  entries(): Iterable<any> {
    return this._entries.map((el) => [el.param, this._simplify(el.value, true)]);
  }

  /**
   * Get all params names
   * @returns {Iterable}
   */
  keys(): Iterable<string> {
    return this._entries.map((el) => el.param);
  }

  /**
   * Get all params values
   * @returns {Iterable}
   */
  values(): Iterable<string | string[]> {
    return this._entries.map((el) => this._simplify(el.value, true));
  }

  /**
   * Delete param
   * @param {string} name - param name
   */
  delete(name: string): void {
    this._entries = this._entries.filter((el) => el.param !== name);
  }

  /**
   * Allows iteration through all values contained in this object via a callback function
   * @param {Function} cb
   */
  forEach(cb: (value: string | string[], param: string) => void) {
    this._entries.forEach((el) => cb(this._simplify(el.value, true), el.param));
  }

  /**
   * sorts all key/value pairs contained in this object in place
   * @returns {undefined}
   */
  sort(): void {
    this._entries.sort((el1, el2) => {
      const param1 = el1.param.toUpperCase();
      const param2 = el2.param.toUpperCase();
      if (param1 < param2) {
        return -1;
      }
      if (param1 > param2) {
        return 1;
      }
      return 0;
    }).forEach((el) => (el.value as string[]).sort());
  }

  /**
   * Returns an object with each property name and value corresponding to the entries of class
   * @param {Boolean} asPrimitives - save values as primitives if possible
   * @returns {Object}
   */
  toObject(asPrimitives: boolean = true): any {
    return this._entries.reduce((result: any, entry: URLEntry) => {
      result[entry.param] = this._simplify(entry.value, asPrimitives);
      return result;
    }, {});
  }

  /**
   * Returns a string with each property name and value corresponding to the entries of class
   * @returns {string}
   */
  toString(): string {
    const resultString = this._entries.reduce((result: string[], entry: URLEntry) => {
      result.push(`${entry.param}${entry.param !== 'page' ? `/${encodeURIComponent((entry.value as string[]).join('_'))}` : entry.value}`);
      return result;
    }, []).join('/');

    return resultString.length ? `/${resultString}` : '';
  }
}
