/**
 * Utils and constants to work with current url location
 * */
const location = window.location;

export const PROTOCOL = ('https:' === document.location.protocol) ? 'https:' : 'http:';

export const IS_LOCAL = ['127.0.0.1', 'localhost'].indexOf(location.hostname) !== -1;

export function getDomain(url: string) {
  const parser = document.createElement('a');
  parser.href = (url.indexOf('http') === 0 ? '' : 'http://') + url;
  return parser.hostname;
}

/**
 * Determine top level domain (e.g. '.hpe.com')
 * @return {string} current domain
 */
export function getCurrentDomain(): string {
  const hostname = location.hostname;
  const hostSplits = hostname.split('.');

  // Trap for http://localhost/
  // --------------
  if (hostSplits.length === 1) {
    return hostSplits[0];
  }
  // Trap for IP Addresses
  // --------------
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostname)) {
    return hostname;
  }
  return '.' + hostSplits.slice(-2).join('.');
}
