import {promisifyTimeout} from '@exadel/esl/modules/esl-utils/async/promise';
import {Cache} from 'core/helpers/cache';

type RSSCategoryFilterType = 'all' | 'any' | 'exclude-any' | 'exclude-all';

export interface IRSSFeedOptions {
  /** Limit of items to fetch (default 5) */
  maxItems?: number;
  /** Timeout to reject request */
  timeout?: number;
  /** {@link categories} filter mode (default: any) */
  filterType?: RSSCategoryFilterType;
  /** Categories field filter, works in pair with {@link filterType} */
  categories?: string | null | true;
  /** Ignores RSS feed cache */
  nocache?: boolean;
}

export interface IRSSItem {
  title: string;
  category: string | string[];
  description?: string;
  guid?: string;
  link: string;
  pubDate?: string;
  [name: string]: string | string[];
}

const cache = new Cache({
  'ns': 'RSS',
  'long': true,
  'liveTime': 60 * 6 // 6 hours
});

const filterByCategories = (items: Element[], categories: string | true, filterType: RSSCategoryFilterType = 'any'): Element[] => {
  const decoder = document.createElement('textarea');
  decoder.innerHTML = `${categories}`;
  categories = decoder.value;
  const arrCategories = categories.toLowerCase().split('|').map((cat) => cat.trim());

  return items.filter((item) => {
    const itemCategoriesNodes = item.querySelectorAll('category');
    const itemCategories = Array.from(itemCategoriesNodes || []).map((itemCategory) => {
      decoder.innerHTML = itemCategory.textContent;
      return String(decoder.value).trim().toLowerCase();
    });

    if (itemCategories.length === 0) return false;

    const categoryPredicate = (category: string) => itemCategories.indexOf(category) !== -1;
    switch (filterType) {
      case 'any':
        return arrCategories.some(categoryPredicate);
      case 'all':
        return arrCategories.every(categoryPredicate);
      case 'exclude-any':
        return !arrCategories.some(categoryPredicate);
      case 'exclude-all':
        return !arrCategories.every(categoryPredicate);
      default:
        throw new Error(`Unsupported filter type '${filterType}'`);
    }
  });
};

const parseRSSItems = (rawRss: string): Element[] => {
  const parsedRss = (new window.DOMParser()).parseFromString(rawRss, 'text/xml');
  return parsedRss ? Array.from(parsedRss.querySelectorAll('channel > item') || []) : [];
};

const extractParams = (rssItems: Element[]): IRSSItem[] => {
  return rssItems.map((el: Element) => {
    const item: Partial<IRSSItem> = {};
    Array.from(el.childNodes).forEach((prop: Element) => {
      if (item[prop.tagName]) {
        item[prop.tagName] = [].concat(item[prop.tagName]).concat(prop.textContent);
      } else {
        item[prop.tagName] = prop.textContent;
      }
    });
    return item as IRSSItem;
  });
};

const loadRSS = (rss: string, timeout?: number, nochache?: boolean) => {
  const cached = cache.get(rss);
  if (cached && !nochache) {
    return Promise.resolve(cached);
  }
  const fetchRequest = fetch(rss).then((response) => {
    if (!response.ok) throw new Error(`${response.url} responded ${response.status} - ${response.statusText}`);
    return response.text();
  }).then((responseText) => {
    cache.set(rss, responseText);
    return responseText;
  });
  if (typeof timeout === 'number') {
    return Promise.race([
      fetchRequest,
      promisifyTimeout(timeout, Error('Timeout exceeded'), true)
    ]);
  }
  return fetchRequest;
};

/**
 * Requests and filters RSS feed
 */
export const getRSS = (rss: string, options: IRSSFeedOptions): Promise<IRSSItem[]> => {
  options = Object.assign({maxItems: 5}, options);
  return loadRSS(rss, options.timeout, options.nocache).then((result: string) => {
    if (!result) throw new Error('Response is empty');

    const items = parseRSSItems(result);
    if (!items.length) throw new Error('Response has no items');

    const filteredItems = options.categories ? filterByCategories(items, options.categories, options.filterType) : items;

    return extractParams(filteredItems.slice(0, options.maxItems));
  });
};
