import {listen, memoize} from '@exadel/esl/modules/esl-utils/decorators';
import {ESLEventUtils} from '@exadel/esl/modules/esl-event-listener/core';
import {debug} from 'core/log';
import {HPEAssetViewerURLUtils} from '../core/asset-viewer-url-utils';

import type {HPEAssetViewerModal, HPEAssetViewerModalActionParams} from './asset-viewer-modal';

export class HPEAssetViewerModalShared {
  @memoize()
  public static async initModal(): Promise<HPEAssetViewerModal> {
    try {
      const {HPEAssetViewerModal} = await import(/* webpackChunkName: "core/modal/asset-viewer-modal"*/'core/modal/asset-viewer-modal');
      HPEAssetViewerModal.register();
      return HPEAssetViewerModal.buildModal({});
    } catch (e) {
      debug('[HPE AssetViewer Modal]', e);
    }
  }

  public static async init(): Promise<void> {
    ESLEventUtils.subscribe(this, this.updateUrl);
    await this.updateUrl();
  }

  public static async show(params: HPEAssetViewerModalActionParams): Promise<void> {
    const $modal: HPEAssetViewerModal = await this.initModal();
    $modal.show(params);
  }

  public static async hide(params: HPEAssetViewerModalActionParams): Promise<void> {
    if (!memoize.has(this, 'initModal')) return;
    const $modal: HPEAssetViewerModal = await this.initModal();
    $modal.hide(params);
  }

  @listen({event: 'popstate', target: window})
  public static async updateUrl(): Promise<void> {
    const assetId = HPEAssetViewerURLUtils.findAssetSearchParams();
    if (assetId) {
      await this.show({assetId, activator: document.body});
    } else {
      await this.hide({activator: document.body});
    }
  }
}
